.data-extension {
    &_wrapper {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        min-height: 21rem;
    }
}

.selection > .data-extension {
    margin: 0 auto;
    width: 98.3%;
    border-radius: 0.3rem !important;
    background-color: var(--white);
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    height: calc(100vh - 80.5px);
    overflow-y: auto;
    padding: 15px;
}

#basic-mode-dropdown {
    flex: 1;
}

.data-extension-single {
    width: 98.2%;
    margin: 0 auto;
    background: var(--white);
    padding: 0.4rem 1.1rem;
    border-radius: .3rem;
    min-height: calc(100vh - 80.5px);
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
}

.data-sets-dropdown-container {
    height: auto !important;

    .label-container {
        margin-bottom: 5px;
        font-weight: bold;
    }

    .selection {
        height: auto !important;
    }

    .data-sets-dropdown {
    width: 100% !important;
    min-width: 7rem !important;
    border-radius: 0.25rem;

    div.text,
    input {
        padding: 0px 1rem 0 0px;
        width: 100% !important;
    }

    .menu {
        max-height: 10rem;
    }
    }
}
.selectionSourceTypeRadioButton{
    margin-bottom: 1rem;
    margin-right: 2.5rem;
    float: left;
    .radio-label{
        font-weight: Bold;
    }
    .slds-radio_faux{
        margin-right: 0.25rem;
    }
}