.folder-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 25px;
  padding: 0 4px;
  margin: 5px 0;
  box-sizing: border-box;
}

.active-folder-list-item {
  background-color: var(--lightSoftBlue) !important;

  .folder-icon {
    color: var(--white);
  }
}


.folder-container {
  width: 100%;
  padding-left: 16px;
  list-style: none;

  .slds-checkbox__label {
    display: inline-block;
    height: 24.44px;
    margin-top: 3px;


    .greyed-out {
      background-color: var(--lightGreyedOut);
      border-color: var(--disabledGray);

      &::after {
        border-bottom: 2px solid var(--darkGreyedOutBorder) !important;
        border-left: 2px solid var(--darkGreyedOutBorder) !important;
      }
    }
  }

  li {
    span {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0.1rem;

      span {
        font-size: 1.4rem;
      }

      p {
        margin-left: 0.5rem;
        font-size: 1rem;
      }
    }
  }

  .openFolder_icon {
    cursor: pointer;
    margin-top: 0.5em;
    margin-right: 0.3em;
    height: 0.8rem;
    filter: invert(100%);
  }
}

.folder-icon {
  margin-right: 10px;
  color: var(--lightSoftBlue);
}

.folder-children {
  height: auto;
  overflow: hidden;

  .folder-container .openFolder_icon {
    margin-top: 0.4em;
  }

  // Target nested .folder-children
  .folder-children .slds-checkbox__label {
    margin-top: 0px !important;
  }
}

.folders-error-container {
  word-break: break-all;
  text-align: left;
  margin-right: 20px;
}

img .hidden {
  visibility: hidden;
}

.disabled {
  color: var(--disabledGrayBlue) !important;
  cursor: pointer;

  .folder-icon {
    color: var(--disabledGrayBlue);
  }
}

.fw-800 {
  font-weight: 800;
}
