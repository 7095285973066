span.radio-label.beta {
    color: #0070d2;
    font-size: 12px;
    background-color: #cce2f6;
    margin-left: 10px;
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 2px;
}
.tooltip-container {
    position: relative;
    display: inline-block;
  }

.tooltip-content {
    visibility: hidden;
    position: absolute;
    background-color: #706e6b;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    bottom: 100%;
    left: 50%;
    transform: translateX(20%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    font-size: 11px;
}
  
.tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
    margin-top: -5px !important;
}
