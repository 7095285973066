.loading-modal-container {
  background-image: linear-gradient(62deg, #FF9900 0%, #f7cf68 100%);

  &.hide {
    display: none;
  }

  &.without-background-color {
    background-image: none;
  }
}

#loadingmodal-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;

  .lmo {
    width: 26rem;
    height: 16rem;
    border: .1rem solid #FBDBC9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 3px 3px 16px 3px rgba(0,0,0,0.5);
    background-color: #fff;
    border-radius: .25rem;

    &_wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;

      .closeModal {
        line-height: 1rem;
        top: .5rem;
        right: 0;
        position: absolute;
        font-size: 2.7rem;
        border: none;
        cursor: pointer;
      }

      .demo-only_viewport {
        transform: translateY(2.5rem);

        .slds-spinner {
          color: var(--primaryOrange) !important;

          &::after, &::before {
            background-color: var(--primaryOrange) !important;
          }

          .slds-spinner__dot-a::before, .slds-spinner__dot-a::after, .slds-spinner__dot-b::before, .slds-spinner__dot-b::after {
            background-color: var(--primaryOrange) !important;
          }
        }
      }
    }

    &-footer {
      transform: translateY(4rem);
      font-size: 1.4rem;
    }
  }
}

#DE-loadingmodal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;

  .lmo {
    width: 26rem;
    height: 16rem;
    border: 0.1rem solid #fbdbc9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 3px 3px 16px 3px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    border-radius: 0.25rem;

    &_wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      position: relative;

      button {
        line-height: 1rem;
        top: 0.5rem;
        right: 0rem;
        position: absolute;
        font-size: 2.7rem;
        border: none;
      }

      .demo-only_viewport {
        transform: translateY(2.5rem);

        .slds-spinner {
          color: var(--primaryOrange) !important;
          &::after,
          &::before {
            background-color: #FF9900 !important;
          }

          .slds-spinner__dot-a::before,
          .slds-spinner__dot-a::after,
          .slds-spinner__dot-b::before,
          .slds-spinner__dot-b::after {
            background-color: #FF9900 !important;
          }
        }
      }
    }

    &-footer {
      bottom: 0.5rem;
      position: absolute;
      font-size: 1.3rem;
      line-height: 1.4;
      color: var(--blackTitle);
      text-align: center;

      &.with-children-only {
        b {
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .loading-text-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 26rem;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;

    .bold-loading-text {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      white-space: nowrap
    }
  }
}
