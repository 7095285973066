.banner {
    &_header {
      background-color: #D8EDFF;
      padding: .75rem 0;
      position: relative;
      z-index: 1;
      margin-top: .7rem;
    }
  
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: .3rem;
      margin: 0 auto;
      width: 98.3%;
    }
  }

  .banner-title {
    display: flex;
    justify-content: space-between;
  }

  .banner-span {
    margin-left: 0.5em !important;
  }

  .remove-tab {
    color: gray;
    margin-left: 0.3rem;
    border: none;
    background-color: transparent;
    outline: none;
    transform: translateY(0.03rem);
    :hover {
    color: var(--darkRed) !important;
    }
  }

  #banner {
    background-color: var(--primaryGreen);
    border: none;

    &:hover {
        background-color: var(--greenActive);;
    }
}

.banner_buttons {
  display: flex;
  width: 15%;
  justify-content: flex-end;
  position: relative;
}




  