.available-extension {
  grid-column: 1/6;
  height: 100%;
  max-width: 20vw;
  margin-right: 10px;

  .stickyAE {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0%;
  }

  .section-title {
    white-space: nowrap;
    padding: 0.8rem 0;
    color: var(--blackTitle);
    border-radius: 0.2rem;
    font-weight: 600;

    .guidance-tip-container{
      display: inline-block;
      margin: 0;
      position: absolute;
      top: 0;
    }
  }

  &_input_wrapper {
    display: flex;
    border: 1px solid var(--grayBorder);
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-width: thin;
    overflow: hidden;

    input {
      width: 100%;
      min-width: 0;
      padding: 0.2rem 0.2rem 0.2rem 1rem;
      border: none;
      border-top-left-radius: 0.25rem;
      &::placeholder {
        color: var(--blackOpacity50);
      }
    }

    &_icon_wrapper {
      display: flex;
      border-top-right-radius: 0.25rem;
      border-left: 1px solid var(--darkenLightGrayBorder);

      span {
        padding: 0.7rem 0.8rem;
        display: flex;
        background-color: var(--lightGrayIconBackground);
        border: none;
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .make-space-for-scroll {
    height: 15.2rem;
    border: 1px solid var(--grayBorder);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }

  &_available_collections {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 14.9rem;
    min-height: 14.9rem;
  }

  &_loader {
    position: relative;

    .demo-only {
      height: 14.8rem;

      .slds-spinner {
        top: 47%;
        position: absolute;
        z-index: 1;
      }
    }
  }
}

.available-folders-icon {
  font-size: 1.3rem;
  margin-left: 12px;

}

.cursor-pointer {
  cursor: pointer;
}

.available-folders-filter-icon {
  width: 2.4rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: border 0.15s linear;
  background-color: var(--white);
  border-color: var(--primaryBlue);
  color: var(--primaryBlue);
  border: none;

  &:hover, &:focus {
    background: var(--lightGrayHover);
  }
}

.disabled-available-folders-filter-icon {
  cursor: not-allowed!important;
  background: var(--disabledGray);
  border-color: var(--disabledGray);
  color: var(--white);
}

.border-top-right-radius-0px {
  border-top-right-radius: 0px!important;
}

.disabled {
  background-color: var(--primaryLightGray);
  color: var(--blackTitle);
  padding: 0.5rem 0.4rem;
  display: flex;
  align-items: flex-start;
  position: -webkit-sticky;
  top: 0;
  cursor: default!important;
}

.h-17rem {
  height: 17.2rem!important;
}

.data-sets-tab {
  display: flex;
}

.data-sets-message {
  font-size: 1rem;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24%;
}

.section-notification {
  font-size: 0.9rem;
  color: var(--primaryGreen);
  margin-bottom: 0.8rem;
}


