.userpanel-container {
    height: 100%;
    position: absolute;
    background: var(--white);
    width: 50%;
    right:0;
    top:0;
    border-radius: 0 0 .3rem;
    border-left: 1px solid var(--darkGrayText);
    transform: translateX(100%);
    opacity: 0.5;

    &.active {
        transform: translateX(0%);
        opacity: 1;
    };

    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.userpanel-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 70%;
    overflow: auto;

    .headers, .editContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:60%;
    }

    .row {
    display: flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
    }

    .titlePanel {
    max-width: 100%;
    font-size:18px;
    line-height: 3;

        @media(max-width:1130px){
        font-size:16px;
        }

        @media(max-width:900px){
        font-size:14px;
        }

        &.bold {
        font-weight: 700;
        }
    }

    .closeModal {
    line-height: 1rem;
    top: .5rem;
    right: .5rem;
    position: absolute;
    font-size: 2.7rem;
    border: none;
    cursor: pointer;
    }

    .checkbox {
    height: 16px;
    width: 16px;

        @media(max-width:900px){
        height: 14px;
        width: 14px;
        }
    }
}

.user-footer {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}

.title {
 text-align: center;
 font-size: 18px;
 padding-top: 0.7rem;
 font-weight: 700;

    @media(max-width:1130px){
    font-size:16px;
    }

    @media(max-width:900px){
    font-size:14px;
    }
}