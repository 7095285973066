.tooltip-container {
  position: relative;
  margin: 0 5px;
  cursor: pointer;

  .slds-nubbin_bottom-right {
    position:absolute;
    top: -4.9rem;
    right: -0.9rem;
    width: 16rem;
    text-align: center;
  }

  .assign-user-tooltip-top-position {
    top: -6.5rem;
  }

  .slds-nubbin_top-left {
    position:absolute;
    width: 15rem;
    top: 1.8rem;
    left: -0.9rem;
    text-align: justify;
    line-height: normal !important;
  }

  .slds-nubbin_bottom-left {
    position:absolute;
    top: -4.9rem;
    left: -1rem;
    width: 16rem;
    text-align: justify;
  }

  .slds-nubbin_top-right {
    position: absolute;
    width: 8rem;
    top: 1.85rem;
    left: -6rem;
    text-align: center;
    line-height: normal !important;
  }

  .disabled-feature-link {
    color: var(--lightOrangeBorder) !important;
  }

  .relation-filter-tooltip {
    top: -5.9rem;
  }

  .relation-filter-tooltip-exactly {
    top: -7.3rem;
  }

  .disabled-autorefresh {
    top: -4.3rem;
    right: -1rem;
  }

  .wp-break-spaces {
    white-space: break-spaces;
  }
}

#tooltip-content .slds-popover__body a { color: var(--primaryYellow);}

.slds-button[disabled] *, .slds-button:disabled *{
  pointer-events:auto !important;
}

