.filter-label-toDEName {
  color: var(--blackLabel)
}

.count-option {
  &.filterline-container {
    width: 100%;
    line-height: 2;

    @media screen and (min-width: 1824px) {
      width: 76%;
    }
  }
}

.relation-filter-line__without-count {
  &.filter-line_filter-name {
    min-width: calc(9.8rem - 1.3rem);
    line-height: 1.5rem;
  }
}

.of-before-relation-field {
  padding-right: 0.5rem;
  width: 1.5rem;
}

.without-count-filterline {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 2;
  padding-right: 0.5rem;

  .filter-fields {
    width: 100%;
  }

  span:first-child {
    padding: 0 0.5rem;
    text-align: center;
  }
}

.relation-filterline-criteria {
  width: 100%;
  display: grid;
  grid-template-columns: 29.7% 15% 13.5% auto;
  align-items: center;

  .collectionAlias-count-name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.5rem;

    span:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
    }

    span:nth-child(2) {
      width: 4.5rem;
    }
  }

  .relatedDEName-filterline {
    margin-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 2;
    font-weight: 600;
  }

  .number-filter-value {
    margin-left: 0.5rem;
    padding: 0 0.5rem;
  }

  .slds-select_container {
    min-width: 4rem;
  }

  &.without-count {
    display: grid;
    grid-template-columns: 29.5% 37% 20% 13.5%;
    align-items: center;

    .span-container-without-count {
      text-align: center;
      display: flex;
      align-items: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      padding-left: 0.5rem;

      span:first-child {
        padding: 0;
      }

      span:nth-child(2) {
        width: fit-content;
        padding: 0 0.5rem;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .slds-select_container {
      min-width: 20%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.relation-filter-tooltip-container {
  display: flex !important;
}
