/* RESET CSS */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
  background-color: var(--white);
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: var(--black);
  padding: 0.2em 0;
}

// ----------------
// BASIC STYLING

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  background-color: #ffd196;
  font-family: Salesforce Sans, Arial, sans-serif !important;
  letter-spacing: -0.2px;
  font-size: 15px !important;
  overflow-y: auto;

  button {
    user-select: none;
  }

  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: auto !important;
  }

  /* overwritten swal */
  .swal2-container {
    z-index: 99999;
    font-family: Salesforce Sans, Arial, sans-serif !important;
    overflow-y: auto !important;
  }

  .swal2-popup {
    padding: 0;
    position: relative;
    width: 28rem;
    font-family: Salesforce Sans, Arial, sans-serif !important;
    animation: none !important;
    left: 0.55rem;

    .swal2-footer {
      border-top: 2px solid var(--lightGrayBorder);
      background-color: var(--modalGrayBackground);
      justify-content: center;
      padding: 1.8rem 1rem;
      color: var(--darkGrayTextFooter);
      font-size: 1em;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  .swal-wide {
    width: 35rem;

    .swal2-title {
      padding: 0 !important;
    }

    .error-title {
      position: relative !important;
      top: 0 !important;
    }
  }

  .swal2-header {
    font-family: Salesforce Sans, Arial, sans-serif !important;
    padding: 0 !important;

    .swal2-icon {
      display: none !important;
    }

    .swal2-title {
      padding: 1rem;
      width: 100%;
      justify-content: center;
      height: auto;

      border-bottom: 2px solid var(--lightGrayBorder);
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 1.25;

      .error-title, .warning-title {
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        position: absolute;
        width: 100%;
        height: 3.65rem;
        top: -1rem;
        left: 0;
        line-height: 3.65rem;
        background-image: linear-gradient(
          45deg,
          var(--blackOpacity025) 25%,
          transparent 0,
          transparent 50%,
          var(--blackOpacity025) 0,
          var(--blackOpacity025) 75%,
          transparent 0,
          transparent
        );
        background-size: 64px 64px;
        color: var(--white);
      }

      .error-title {
        background-color: var(--errorRed);
      }

      .warning-title {
        background-color: var(--primaryOrange);
      }
    }
  }

  .swal2-content {
    font-family: Salesforce Sans, Arial, sans-serif !important;
    font-size: 1.05rem;
    width: 90%;
    font-weight: 300;
    margin: 0 auto;
    min-height: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -1rem;
    padding: 0;

    .width_swal {
      width: 25rem;
      padding: 1rem 0;

      .bold-swal {
        font-weight: 700;
      }
    }

    .bold_swal {
      font-weight: bold;
    }

    .swal2-input {
      background-color: var(--white);
      border: 1px solid var(--lightGrayBorder);
      border-radius: 0.25rem;
      width: 90%;
      transition: border 0.1s linear, background-color 0.1s linear;
      display: inline-block;
      padding: 0 1rem 0 0.75rem;
      line-height: 1.875rem;
      min-height: calc(1.875rem + 2px);

      &:active,
      &:focus {
        outline: 0;
        border-color: var(--focusBlue);
        background-color: var(--white);
        box-shadow: 0 0 3px var(--primaryBlue);
      }
    }

    .swal2-radio {
      label {
        .swal2-label {
          font-size: 1rem;
        }
      }
    }
  }

  .swal2-actions {
    font-family: Salesforce Sans, Arial, sans-serif !important;
    position: absolute;
    right: 0.9rem;
    bottom: 0.8rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding:0;

    .swal2-confirm {
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      vertical-align: middle;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 1px solid var(--lightGrayBorder);
      -webkit-transition: border 0.15s linear;
      transition: border 0.15s linear;
      background-color: var(--primaryBlue) !important;
      border-color: var(--primaryBlue);
      color: var(--white) !important;
      border-radius: 0.25rem;
      line-height: 1.875rem;

      &:hover {
        background-color: var(--darkBlue) !important;
        border-color: var(--darkBlue);
        color: var(--white);
      }
    }

    .swal2-cancel {
      margin-right: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      vertical-align: middle;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 1px solid var(--lightGrayBorder);
      -webkit-transition: border 0.15s linear;
      transition: border 0.15s linear;
      background-color: var(--white);
      border-color: var(--lightGrayBorder);
      color: var(--primaryBlue);
      border-radius: 0.25rem;
      line-height: 1.875rem;

      &:hover {
        background-color: var(--lightGrayHover);
      }
    }
  }

  // If one button , make it in center
  .swal2-noanimation {
    .swal2-actions {
      justify-content: center;
      transform: translateX(0.6rem);

      button {
        padding-left: 1rem;
        padding-right: 1rem;
        text-align: center;
        vertical-align: middle;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: 1px solid var(--lightGrayBorder);
        -webkit-transition: border 0.15s linear;
        transition: border 0.15s linear;
        background-color: var(--white);
        border-color: var(--lightGrayBorder);
        color: var(--primaryBlue);
        border-radius: 0.25rem;
        line-height: 1.875rem;
        &:hover {
          color: var(--primaryBlue);
          border-color: var(--lightGrayBorder);
          background-color: var(--lightGrayHover);
        }
      }
    }
  }

  .error-btn-middle {
    transform: translateX(-325%);
    background-color: var(--white) !important;
    color: var(--darkBlue) !important;
    border: 1px solid var(--lightGrayBorder) !important;

    &:hover {
      background-color: var(--lightGrayHover) !important;
    }
  }

  .moveLeft {
    margin-right: 0.5rem;
  }

  // overwritten ligtening design system
  .slds-button_brand {
    box-shadow: 0 2px 2px 0 #413e341f !important;
  }

  .slds-form-element__label,
  .slds-form-element__legend {
    font-size: 1rem !important;
  }

  //NetworkErrorModal styling
  #network-error-modal {
    .slds-modal__header {
      background-image: linear-gradient(
        45deg,
        var(--blackOpacity025) 25%,
        transparent 0,
        transparent 50%,
        var(--blackOpacity025) 0,
        var(--blackOpacity025) 75%,
        transparent 0,
        transparent
      );
      background-size: 64px 64px;
      color: var(--white);
      background-color: var(--errorRed);
    }

    .network-error-body {
      min-height: 6rem;
      display: flex;
      text-align: center;
      align-items: center;
    }
  }

  .other-than-network-error-modal {
    img {
      padding: 2rem;
    }
  }

  .network-error-body {
    min-height: 6rem;
    display: flex;
    text-align: center;
    align-items: center;
  }

  .Toastify__toast-container--top-right {
    top: 0;
    right: 0;
    height:0;
    position: relative;
  }

  .Toastify {
    height: 0;
    display: flex;
    justify-content: flex-end;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--primaryOrange);
  background-image: var(--bodyBackground);
  height: 100%;
  padding-right: 0px !important;

  button {
    outline: none !important;
  }
  button:focus,
  input:focus {
    z-index: 20;
  }

  .slds-select {
    line-height: 1.2rem;
  }
}

.dark_HTML {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 2049px) {
  .available-extension,
  .available-fields {
    max-width: 19.8vw !important;
  }
}

@media only screen and (min-width: 1821px) {
  .selections_right {
    &_header {
      height: 1.86rem !important;
    }
  }

  .selections_center {
    width: auto !important;

    &.center-bigger {
      width: auto !important;
    }

    &_header {
      height: 1.86rem !important;
    }
  }
}

@media only screen and (max-width: 1707px) {
  .selections_left {
    width: 15rem !important;
  }

  .selections_center {
    width: 45rem !important;

    &.center-bigger {
      width: 60rem !important;
    }
  }
  .available-extension,
  .available-fields {
    max-width: 19.8vw !important;
  }
}

@media only screen and (max-width: 1517px) {
  .selections_center {
    width: 40rem !important;

    &.center-bigger {
      width: 50rem !important;
    }

    &_header {
      height: 1.86rem !important;
    }
  }

  .selections_right {
    &_header {
      height: 1.86rem !important;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .selections_center {
    width: 35rem !important;

    &.center-bigger {
      width: 40rem !important;
    }
    .available-extension,
    .available-fields {
      max-width: 19.75vw !important;
    }
  }
}

@media only screen and (max-width: 1175px) {
  .selections_center {
    width: 32rem !important;
  }
  #controlers-RL {
    display: none;
  }
  #target-DE-dropdown {
    width: 23rem !important;

    div.text,
    input {
      width: 20rem;
    }
  }
}

@media only screen and (max-width: 1150px) {
  html {
    font-size: 14.5px !important;
  }
}

@media only screen and (max-width: 1150px) {
  .ob-name,
  .oh-name {
    width: 11rem;
  }
  .oh-name div {
    width: 11rem;
  }
}

@media only screen and (max-width: 1130px) {
  html {
    font-size: 13.9px !important;
  }

  .ob-name,
  .oh-name {
    width: 9rem !important;
  }
  .oh-name div {
    width: 9rem !important;
  }
}

@media only screen and (max-width: 1120px) {
  html {
    font-size: 13.5px !important;
  }

  #target-DE-dropdown {
    width: 18rem !important;

    div.text,
    input {
      width: 15rem;
      font-size: 13.25px;
    }

    input {
      margin-top: 1px !important;
    }

    div.text {
      margin-left: 6.5px;
    }
  }
}

@media only screen and (max-width: 1080px) {
  html {
    font-size: 13.25px !important;
  }

  #target-DE-dropdown {
    width: 16rem !important;

    div.text,
    input {
      width: 13rem;
    }
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 13px !important;

    .selections_center {
      width: 27rem !important;
    }
  }
}

@media only screen and (max-width: 975px) {
  html {
    font-size: 12.8px !important;
  }
}

@media only screen and (max-width: 950px) {
  html {
    font-size: 12.6px !important;
  }
}

@media only screen and (max-width: 925px) {
  html {
    font-size: 12.3px !important;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 11.9px !important;
  }
}

@media only screen and (max-width: 875px) {
  html {
    font-size: 11.5px !important;
  }
}

@media only screen and (max-width: 850px) {
  html {
    font-size: 11.2px !important;
  }
}

@media only screen and (max-width: 825px) {
  html {
    font-size: 10.5px !important;
  }
}

@media only screen and (max-width: 767px) {
  [aria-describedby='modal-content-id-1'],
  [aria-describedby='modal-content-id-2'],
  [aria-describedby='modal-content-id-4'] {
    left: 23% !important;
  }
  [aria-describedby='modal-content-id-3'] {
    left: -7.5% !important;
  }
}

details {
  height: 100%;

  .show-less, .show-more {
    color: #006dcc;
    cursor: pointer;
  }

  .show-less {
    display: none;
  }

  .show-more {
    display: inline-block;
  }
}

details[open] {
  .show-more {
    display: none;
  }

  .show-less {
    display: inline-block;
  }
}

.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.display-block {
  display: block;
}

.text-start {
  text-align: start;
}

.break-all{
  word-break: break-all;
}
