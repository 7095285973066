.selections_list {
  display: flex;
  color: var(--darkGrayText);
  border-left: .5rem solid var(--lightGrayTableHeader);

  .disabled-overlay{
    background-color: var(--disabledOverlayOrange);
  }

  // LEFT
  .selections_left {
    flex: 1 1;
    min-width: 15rem;

    &.waterfall {
      min-width:17rem;
    }

    &_header {
      height: 1.86rem;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: var(--lightGrayTableHeader);
      display: flex;
      font-weight: 700;

      li {
        height: 100%;
        line-height: 2rem;
        padding: 0 .5rem;
      }

      &-name {
        width: 100%;
        span {
          cursor: pointer;
          margin-left: .4rem;

          svg {
            margin-left: -.1rem;
          }
        }
      }
    }

    .selections_left_body {
      display: flex;
      height: 2rem;
      align-items: center;
      border-top: 1px solid var(--lightGrayBorder);

      &:nth-last-child(1) {
        border-bottom: 1px solid var(--lightGrayBorder);
      }

      li {
        line-height: 2rem;

        span {
          text-overflow: ellipsis;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &-name {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: .5rem;
        display: flex;

        .drag_drop_selection {
          vertical-align: middle;

          &:active {
          cursor: grabbing;
          }

          svg:hover {
            cursor: grab;
          }
        }
      }
    }
  }

  // CENTER
  .selections_center {
    min-width: 20rem;
    transition: 0.5s all;

    &.center-bigger {
      width: 40rem;
    }

    &_header_container {
      min-height: 150px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      overflow-x: auto;
      overflow-y: hidden;
    }

    &_header {
      height: 1.86rem;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: var(--lightGrayTableHeader);
      display: flex;
      width: 67.5rem;
      justify-content: flex-start;
      z-index: 2;
      font-weight: 700;
      line-height: 2rem;

      &.waterfall {
        width:55.5rem;
      }

      li:first-child {
        border-left:  1px solid var(--lightGrayBorder);
      }

      li {
        width: 10.2rem;
        white-space: nowrap;
        border-right: 1px solid var(--lightGrayBorder);
        padding-left: .4rem;

        &.selections_center_header-status {
          width: 4rem;
          min-width: 0rem;
          max-width: 4rem;
        }

        &.selections_center_header-records {
          width: 7rem;
          min-width: 0rem;
          max-width: 7rem;
        }

        span {
          cursor: pointer;
          margin-left: .4rem;

          svg {
            margin-left: -.1rem;
          }
        }

        &.selections_center_header-updatedBy {
          padding-right: .3rem;
          width: 10.8rem;
        }

        &.selections_center_header-taskCompletedDate{
          position: relative;
          .guidance-tip-container{
            position: absolute;
            display: inline-block;
            margin: 0;
          }
        }
      }
    }

    &_body {
      height: 2rem;
      align-items: center;
      width: 67.5rem;
      display: flex;
      justify-content: flex-start;
      border-top: 1px solid var(--lightGrayBorder);

      &.waterfall {
        width:55.5rem;

        li:first-child {
          padding-left: 0;
          padding-right: 0.2rem
        }

        li {
          width: 10.3rem;
        }
      }

      &:nth-last-child(1) {
        border-bottom: 1px solid var(--lightGrayBorder);
      }

      li {
        width: 10rem;
        padding-left: .4rem;
        line-height: 2rem;

        &.selections_center_body-status {
          max-width: 4.2rem;
          min-width: 0;
          width: 4.2rem;
          text-align: center;
          margin-left: -.2rem;
        }

        &.selections_center_body-nrOfRecord {
          width: 7rem;
          min-width: 0rem;
          max-width: 7rem;
        }

        &.overview-selection-created-by-list, &.overview-selection-updated-by {
          overflow: hidden;

          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.overview-selection-updated-by {
          width: 10.3rem;
        }

        .overview-selection-completed-date {
          padding-left: .4rem;
          font-size: smaller;
        }

        .overview-selection-updated-at {
          padding-left: .4rem;
          font-size: smaller;
        }

        .overview-selection-created-at {
          font-size: smaller;
        }
      }
    }
  }

  // RIGHT
  .selections_right {
    min-width: 5.5rem;

    &_header {
      height: 1.86rem;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: var(--lightGrayTableHeader);
      display: flex;
      justify-content: flex-start;
      z-index: 2 !important;
      font-weight: 700;
      padding: 0 1rem;

      li {
        white-space: nowrap;
        height: 100%;
        line-height: 2rem;
      }
    }

    &_body {
      border-top: 1px solid var(--lightGrayBorder);
      padding: 0 1rem;
      height: 2rem;

      &:nth-last-child(1) {
        border-bottom: 1px solid var(--lightGrayBorder);
      }
      .icons-overview {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 1.6rem;
          height: 1.6rem;
          margin: 0.1rem;
        }
      }

      .icon-start {
        justify-content: flex-start;
      }
    }
  }
}

// status icons
.status-queued-orange {
  fill: var(--primaryOrange);
}

.status-completed-green {
  fill: var(--primaryGreen);
}

.status-error-red {
  fill: var(--primaryRed);
}

.scheduled-column-title {
  width: 6.2rem !important;
  min-width: 6rem;
  .upgrade-badge-icon {
    top: -5px;
    bottom: 0;
    right: 5.5em;
  }
  &.disabled-overlay{
    border-right: 1px solid white !important;
    .template-header-text{
      opacity: 0.6;
      margin-left: 0px;
    }
    .tooltip-container{
      position: absolute;
      right: 5.5em;
      top: -0.6em;
    }
  }
}

.template-column-title {
  width: 6.2rem !important;
  padding-right: 0.7rem;

  .template-header-text{
    margin-left: 0 !important;
  }

  .upgrade-badge-icon {
    top: -5px;
    bottom: 0;
    right: -10px;
  }

  .sorting {
    margin-left: 0.2rem !important;
  }

  &.disabled-overlay{
    border-left: 1px solid white !important;
    cursor: pointer;
    .sorting{
      pointer-events: none;
    }
    .template-header-text{
      opacity: 0.6;
    }
    .tooltip-container{
      position: absolute;
      right: -0.5em;
      top: -0.6em;
    }
  }
}

.schedule-tooltip-position {
  position: relative;
  right: -6.2em;
  top: -1.9em !important;
}

