.selection-chain-container {
    margin: 5px 0.5rem;
    width: 50%;

    .without-selected-selection-container {
        width: 100%;
        padding: 3rem;
        display: flex;
        min-height: calc(100vh - 125px);
        justify-content: center;
        align-items: center;
        border: 1px solid var(--lightGrayBorder);
        border-radius: 0.25rem;
        height: 100%;

        &.true {
            min-height: auto;;
        }

        p {
            font-weight: 500;
            letter-spacing: 0.1rem;
            line-height: 2rem;
            text-align: center;
            padding: 1rem;
            color: var(--grayBorder);
            text-transform: uppercase;
            border: .1rem dashed transparent;
            transition: .3s all !important;
            border: var(--transparentBorder)
        }

        .slds-spinner {
            position: relative;
            top: 0;
            left: 0;
        }
    }
}