.virtualized-dropdown {
    position: absolute;
    width: 100% !important;
    margin-top: -15px;

  }
  
  .virtualized-list {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    height: 40vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .slds-truncate {
      overflow: unset;
    }
  
    .dropdown-option {
      margin-top: 0.5rem;
    }
  }
  
  .virtualized-list-item {
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #dddbda;
    cursor: pointer;
  }
  
  .virtualized-list-item:hover {
    width: 100%;
    background-color: #dddbda;
  }
  
  .selected_data {
    background-color: #005fb2;
    width: 100%;
    color: #ffffff;
  }

  .row-dropdown {
    display: flex; 
  }
  .input-size {
    width: 100% !important;
  }
  .list-top {
    position: absolute;
  }
