.cancel-save-retention {
    margin-bottom: 1rem;
}

.toggle-retention-policy {
    margin-left: 2.2rem;
}

.mb-0px {
    margin-bottom: 0px !important;
}