.running-query-icon {
    animation: spin-animation 3s linear infinite;
    display: inline-block;
    fill: var(--primaryOrange);
}

.queued-for-waterfall-icon {
  fill: var(--primaryOrange);
}

@keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .scheduled-icon-container {
    text-align: end;
    margin-right: 24px;
  }

  .scheduled-icon {
    fill: var(--primaryGreen);
  }

  .scheduled-width{
    &.disabled-schedule{
      background-color: var(--disabledOverlayOrange);
      border-right: 1px solid white;
      cursor: pointer;
      width: 6.65rem !important;
      margin-left: -0.65em;
      .span-scheduled-icon{
        opacity: 0.6;
      }
    }

    .span-scheduled-icon{
      &.disabled-schedule{
        pointer-events: none;
      }
    }
  }

  .template-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &.disabled-schedule{
      background-color: var(--disabledOverlayOrange);
      border-left: 1px solid white;
      i{
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  .scheduled-width, .template-list-item {
    width: 6.8rem!important;
  }

  .template-list-item {
    display: flex;
    justify-content: center;
  }

  .width-schedule-on {
    width: 10.3rem;
  }

  .width-schedule-off {
    width: 10.8rem;
  }

  .schedule-funnel-icon {
    position: relative;
    top: 0.16em;
    color: var(--primaryBlue);
    font-size: 1.1em;
  }

  .ghost-funnel {
    pointer-events: none;
    opacity: 0.0;
  }

  .overview-list-hover {
    background-color: var(--lightGrayBorder);
  }
