.date-difference {
  .title {
    font-weight: 600;
    display: block;
    margin-bottom: 7px;
    text-align: left;
  }
  .slds-form-element__label {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .description {
    font-size: 15px;
    font-weight: normal;
    color: var(--grayText);
  }
  .date-difference-title{
    font-weight: 600;
  }
  .data-extension, .field, .output {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  .radio-button-group {
    margin-top: 10px;
  }
  .data-extension select {
    line-height: normal;
  }
  .form-element-label {
    flex-basis: 37%;
  }
  .custom-values-input > div > div > div > b {
    color: var(--primaryDarkGray);
  }
  .form-control-elements {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    .slds-input {
      padding-right: 0;
      margin-right: 10px;
      width: 80px;
    }
  }
  fieldset {
    legend {
      margin-bottom: 5px;
    }
    .slds-radio__label {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1430px) {
    .form-element-label {
      flex-basis: 43%;
    }
  }
}

.dropdown-inline-group {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

#data-extensions-dropdown {
  max-height: calc(1.875rem + (1px * 2)) !important;
  width: 100%;

  &.offset-dropdown{
    .dropdown.icon{
      top: 0 !important;
      right: 3px;
    }
    .text{
      bottom: 0px !important;
    }
  }

  &.ui.selection.dropdown {
    min-height: calc(1.875rem + (1px * 2)) !important;
    margin-right: 0px;

    .dropdown.icon {
      margin: 0px;
      padding: 0px;
      position: absolute;
      top: 20%;
    }
  }

  &.ui.search.dropdown > .text {
    bottom: 5px;
  }

  &.ui.selection.active.dropdown .menu {
      max-height: 8em;
    }
}
