.sort-way-box {
  fieldset {
    .slds-form-element__control {
      .prio-dedup-sort-mode {
        display: flex;

        .slds-button {
          flex: 1;

          .slds-radio_button__label {
            text-align: center;
            vertical-align: middle;
            width: 100%;
          }
        }
      }
    }
  }
}
