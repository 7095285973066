#no-data-extension-scope-modal {
  .onboarding-screen__image{
    width: 30%;
    margin: 0 auto;
    display: block;
  }

  .information-text{
    padding: 0.5em;
    width: 90%;
    margin: 1em auto 0 auto;

    li{
      list-style-type: disc;
    }
  }

  .onboarding-screen__title{
    padding: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }
}
