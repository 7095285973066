.scope-des-modal{
  #scoped-des-modal-dialog{
    .scoped-des-modal-container{
      width: 58%;

      .alias-tooltip{
        left: 29%;
      }

      .description-tooltip{
        left: 53.5%;
      }
    }
  }


  .onboarding-screen{
    .onboarding-screen__title{
      padding: 1em;
      font-size: 1.5em;
      font-weight: bold;
      text-align: center;
    }

    .onboarding-screen__image{
      width: 10%;
      margin: 0 auto;
      display: block;
    }

    .onboarding-screen-body{
      padding: 1em;
      width: 90%;
      margin: 0 auto;
      .onboarding-screen__text{
        font-size: 1.1em;
        line-height: 2em;
        list-style-type: disc;
      }
    }

    .onboarding-screen-footer{
      padding: 1em;
      text-align: right;
    }

    .bold-text{
      font-weight: bold;
    }
  }
}

.swal-changes{
  overflow-y: auto;
  max-height: 200px;
  margin-bottom: 20px;
}

.swal-changes__title{
  font-size: 1em;
  font-weight: 600;
  text-align: left;
}

.swal-changes__list{
  font-size: 0.9em;
  text-align: left;
  list-style-type: disc;
  padding-left: 1.5em;
}
