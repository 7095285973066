.multi-select {
    // Input
    input {
        padding-right: 1.5rem !important;

        &::placeholder { color: var(--primaryBlack); }
    }

    // Options (dropdown)
    .slds-dropdown {
        font-size: calc(100% - 10%);

        &:empty {
            display: none;
        }
    }

    // Pills
    .slds-listbox_selection-group {
        height: 100%;
        padding-right: 0;
        overflow: unset;

        & .slds-listbox-item {
            padding:0.125rem 0.125rem 0.125rem 0;
            max-width: calc(2em * 10);

            & .slds-pill { background: var(--primaryLightGray); }
            & .slds-pill__remove { cursor: pointer; }
        }

        & .slds-pill:focus {
            border-color: var(--lightGrayBorder);
            box-shadow: none;
            -webkit-box-shadow: none;
        }
    }
}

.slds-pill__label {
    margin-top: -.1rem;
}

.add-logic-link {
    margin-top: -.15rem;
}

.slds-button__icon.drag-and-drop {
    margin-top: unset !important;
}
.virtualized-list{
    height: 40vh;
}

