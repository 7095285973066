#container-data-retention-policy {
  position: relative;
  .slds-modal__content {
    padding-bottom: 0rem !important;
    position: relative;

    .disable-to-update {
      label, select, .slds-checkbox_faux {
        cursor: not-allowed !important;
      }

      input:focus + .slds-radio__label .slds-radio_faux,
      input:focus + .slds-checkbox__label .slds-checkbox_faux,
      input:focus + .slds-checkbox_faux_container .slds-checkbox_faux {
          border: 1px solid var(--lightGrayBorder);
          box-shadow: none;
      }

      input:disabled + .slds-checkbox_faux_container .slds-checkbox_faux {
        border-color: var(--disabledGrayText);
      }
    }


    // PART ONE
    .DRP-part-one {
      display: flex;
      padding-bottom: 0.9rem;

      .tooltip-container {
        button {
          margin: 0
        }

        .retention-read-only {
          min-width: 15rem;
          letter-spacing: 0.6px;
        }
      }

      .retention-setting-label {
        margin-right: 1.2rem;
      }

      // switcher
      .slds-form-element {
        .slds-checkbox_toggle {
          .slds-checkbox_faux_container {
            margin-left: .25rem;
            .slds-checkbox_faux {
              height: 1.55rem;
            }
            .slds-checkbox_off, .slds-checkbox_on {
              margin-top: .2rem;
            }
          }
        }
      }
    }

    // PART TWO
    .DRP-part-two {
      padding-bottom: 1rem;
      display: flex;
      position: relative;
      font-size: .9rem;

      &_1 {
        display: flex;
        min-width: 27%;
      }

      &_2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .delete-label, .label-period {
          font-weight: 600;
          margin-left: 2.2rem;
          font-size: .9rem;
        }

        .label-period  {
          margin-top: .7rem;
        }

        // Delete container - first one
        .delete-container {
          background-color: var(--modalGrayBackground);
          border: 1px solid var(--lightGrayBorder);
          border-radius: 5px;
          padding: .5rem;
          margin-bottom: .5rem;
          margin-top: .2rem;
          margin-left: 2.2rem;
          width: 16.67rem;

          // fieldset
          .slds-form-element {
            margin-bottom: 0px;

            .slds-form-element__control {
              .slds-radio {
                margin-top: .3rem;

                .slds-radio__label {
                  width: 16rem;
                  display: block;
                }
              }
            }
          }
        }

        .disable-container {
          opacity: .3;
        }

        // Period container -second one
        .period-container {
          margin-bottom: 2rem;
          background-color: var(--modalGrayBackground);
          border: 1px solid var(--lightGrayBorder);
          border-radius: 5px;
          padding: .5rem;
          width: 16.67rem;
          margin-top: .7rem;
          margin-left: 2.2rem;

          .disable-container {
            opacity: .3;
          }

          //fieldset
          .slds-form-element {
            position: relative;

            // First line
            .wrapper-firstPart {
              display: flex;
              align-items: center;

              //After
              .slds-form-element__control {
                span {
                  white-space: nowrap;
                  label {
                    display: flex;
                    align-items: center;
                  }
                }
              }

              .group-slds-after {
                display: flex;
                align-items: center;
                justify-content: space-between;

                // first one
                .slds-form-element__control {
                  input {
                    padding: 0 2px !important;
                    font-size: 1rem !important;
                    min-height: 2rem !important;
                  }
                }

                .slds-form-element {
                  margin-bottom: 0rem;
                  margin-right: 0rem;

                  // second one
                  .slds-form-element__control {
                    .slds-select_container {
                      select {
                        height: 2rem !important;
                        margin-top: 0rem !important;
                        max-width: 6rem !important;
                        margin-left: 0 !important;
                        font-size: 1rem !important;
                      }
                    }
                  }
                }
              }

              // days/weeks/months/years
              div:nth-child(3){
                margin-bottom: 0rem;
                div {
                  div {
                    select {
                      padding: 0 !important;
                      padding-left: .5rem !important;
                      margin: 0 !important;
                      margin-left: .3rem !important;
                      height: 2rem !important;
                      font-size: .9rem !important;
                    }
                  }
                }
              }
            }

            // Second line - Reset period on import
            .wrapper-secondPart {
              margin: .3rem 0;
              .slds-form-element__control {
                .slds-checkbox {
                  text-align: left;
                }
              }
            }

            // Third line - On
            .wrapper-thirdPart {
              .slds-radio {
                label {
                  margin-right: 10px;
                }
              }
            }

            // Date picker
            .react-datepicker-wrapper {
              div {
                input {
                  transform: translateY(.7rem);
                  width: 11.4rem;
                  font-size: 1rem !important;
                }
              }
            }

            // date picker (when it shows up)
            .react-datepicker-popper {
              left: -2rem !important;
              position: absolute !important;
              z-index: 99999999999999999 !important;
              will-change: transform !important;
              top: -1rem !important;
              transform: translate3d(0px, -175px, 0px) !important;

              div {
                .react-datepicker {
                  .react-datepicker__month-container {
                    .react-datepicker__header {
                      .react-datepicker__current-month {
                        margin-top: .3rem;
                      }
                      .react-datepicker__day-names {
                        margin: .2rem 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.DRP-footer {
  button:nth-child(2){
    margin-left: .5rem !important;
  }
}

.DRP-backgroundTransparent {
  background: transparent;
}