.highlighted-container {
    width: 100%;
    margin-left: 0.5rem;
    font-size: 1rem !important;

    b {
        font-weight: 800;
    }
}

.unmark {
    padding: 0.1rem;
    margin: 0;
    font-size: 1rem !important;
}
