.scoped-data-extension-list {
  .alias-tooltip{
    display: inline-block;
    position: relative;
    left: 28.5%;
    width: auto;
    top: 1.5em;
    z-index: 1;
  }

  .description-tooltip{
    display: inline-block;
    position: relative;
    left: 53%;
    width: auto;
    top: 1.5em;
    z-index: 1;
  }

  #scope-de-table {
    .error{
      border-color: var(--lightRed);
    }
    textarea {
      background-color: white;
      border: 1px solid #dddbda;
      border-radius: 0.25rem;
      margin-top: -8px;
      width: 90%;
    }
    .slds-table {
      font-size: 0.9em;
      thead {
        tr {
          th {
            div{
              top: 5px;
              background-color: transparent;
            }
          }
        }
      }

      td[data-label="Name"] {
        width: 25%;
      }

      td[data-label="Alias"] {
        width: 25%;
      }
    }
  }

  .pagination {
    margin-top: 1em;
    margin-right: 1em;
    float: right;
    font-size: 0.9em;

    .active-page {
      width: 20px;
      height: 20px;
      border: 2px solid var(--primaryBlue);
      background-color: var(--primaryBlue);
      border-radius: 50%;
      color: var(--white);
      box-sizing: border-box;
      padding-left: 4px;
    }
  }

  .no-scoped-de{
    text-align: center;
    font-size: 1em;
    margin: 2em;
    font-weight: 600;
  }

  .scope-data-extension-search{
    margin-bottom: 20px;
    display: flex;
  }

  .slds-text-heading_medium{
    font-weight: 600;
    color: var(--darkGrayText);
  }
}
