.audit-logs {
  background-color: var(--white);
  height: calc(100% - 93px);
  border-radius: .3rem;
  padding: 1.2rem 1.5rem;
  line-height: 1.2;
  overflow-y: scroll;

  .slds-spinner {
    position: relative;
  }

  .bold-text {
    font-weight: bold;
  }

  &__title {
    margin-bottom: 1.5em;

    .info-header {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 600;
      padding-left: 10px;
    }

    .info-text {
      font-size: 15px;
      padding: 0 0 0.5rem 0;
      color: var(--grayText);
      line-height: normal;
    }

    .settings-title {
      margin: 0.8rem 0;
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  &__actions {
    &_header {
      span {
        font-size: 16px;
        font-weight: bold;
      }

      margin-bottom: 1em;
      display: inline-block;
    }

    .date-range {
      display: flex;
      gap: 10px;

      .range-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0.5em;
        color: var(--darkGrayText);
      }

      .custom-calendar-header {
        display: flex;
        justify-content: space-between;
        padding: 0.2em 0.6em;

        &__month {
          flex: 2 1 auto;
          display: flex;
          justify-content: space-between;

          select {
            border: none;
            appearance: none;
            font-size: 13px;
          }

          button {
            border: none;
            background-color: transparent;
          }

          button:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }

        &__year {
          flex: 1 1 auto;

          select {
            border-color: var(--lightGrayBorder);
            border-radius: 3px;
            font-size: 12px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            padding: 0.5em 2.5em 0.5em 0.5em;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
            background-position: calc(100% - .5rem), 100% 0;
            background-size: 1.5em 1.5em;
            background-repeat: no-repeat;
          }
        }
      }

      &__actions {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &_button {
      margin-top: 23px;
    }
  }

  &__generated {
    margin-top: 1.5em;

    td[data-label='Link'] {
      .slds-m-left_small{
        margin-left: 6.5rem !important;
      }
    }

    div[title='Download Link'] {
      margin-left: 6.5rem;
    }

    &_header {
      span {
        font-size: 16px;
        font-weight: bold;
      }

      margin-bottom: 1em;
      display: inline-block;
    }

    &_list_item {
      display: flex;
      margin-bottom: 0.5em;

      >* {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }

    &_list_item.list_headers {
      font-weight: bold;
      margin-bottom: 1em;
      ;
    }

    &_list_item_empty {
      color: var(--lightGrayDisabledItem);
      text-align: center;
      margin-top: 3em;
    }
  }

  &__note {
    font-size: 15px;
    color: var(--darkGrayText);
    margin-top: 1em;

    .email {
      color: black;
    }
  }

  .react-datepicker__day--today {
    color: black;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .tooltip-container {
    display: inline;
  }

  .link-expired {
    color: var(--lightGrayDisabledItem);
    cursor: not-allowed;
  }
}
