@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.notifications-box {
  z-index: 10;
  left: 2.9rem;
  right: 4rem;
  margin: 0 1rem;

  #notification-message {
    margin-right: 10.5rem;
  }

  .slds-theme_offline {
    background-color: var(--lightGrayBorder);
    color: var(--black);

    #notification-message {
      a {
        color: var(--black);
      }

      a:hover {
        text-decoration: underline;
        text-decoration-color: var(--black);
      }
    }

    svg {
      fill: var(--black);
    }
  }

  .slds-notify_alert {
    opacity: 1;
    margin-top: .5rem;
    transition: all 1s;
    animation: 1s ease-out 0s 1 slideInFromTop;
    border: 1px solid var(--blackOpacity50);
    word-wrap: anywhere;
  }

  .slds-button_icon {
    vertical-align: baseline !important;
  }

  .slds-notify__close {
    & > span {
      vertical-align: text-top;
      margin-right: .3rem;
    }

    input {
      margin-right: .3rem;
    }
  }

  .slds-theme_error {
    .slds-button_icon {
      color: var(--white);
    }
  }
}
