.template-settings-modal {
    min-width: 36rem;

    .template-settings-content {
        padding: 20px;

        .checkbox-container {
            display: flex;
        }

        .template-description-container, .selection-description-container,
        .template-instructions-container {
            margin: 10px 0;
        }

        textarea {
            width: 100%;
            padding: 10px;
            resize: none;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 5px;
    }
}