.extraInfo {
    margin: 10px 0px;
    text-align: center;
    background-color: var(--white);
    border-radius: .3rem;
    padding: 5px 0px;
}

.usersTable {
    height: calc(100% - 93px);
    border-radius: .3rem;
    padding-top: 2.5rem;

    .slds-cell-fixed {
        padding-top: 0.7rem;
    }

    & > div {
        height: 100%;
    }

    td {
        padding: 1rem 0;
        line-height: 2rem;

        &:first-child > div {
            margin-left: .75rem;
        }
    }

    td:nth-child(4) {
        padding-left: 0.5rem;
    }
}
.userPanel {

    th:nth-child(3) {
        padding-left: 10.5rem;
    }

    th:nth-child(4) {
        padding-left: 5.5rem;
    }
}
.slds-hint-parent {
    &.users_row {
        .fullName {
            color: var(--darkBlue);
            cursor: pointer;
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
        td:nth-child(1) {
            z-index: 4;
        }

        td:nth-child(2) {
            z-index: 3;
        }

        td:nth-child(3) {
            padding-left: 10.5rem;
            z-index: 2;
        }

        td:nth-child(4) {
            padding-left: 5.5rem;
            z-index: 1;
        }

        td:nth-child(5) {
            padding-left: 0.5rem;
        }

        .slds-truncate {
            overflow: inherit;
            text-overflow: inherit;
        }
}
}

.user-licenses {
    h6:nth-child(1) {
        margin-bottom: 0.5rem;
    }

    h6 {
        span {
            color: var(--primaryBlue);
        }
    }
}
