// Whole screen
.relation-container {
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .relation-panel-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: var(--white);
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        overflow-y: scroll;

        .relation_wrapper {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 2rem 0rem 0rem 0rem;
            height: 100%;
            min-height: calc(100vh - 226px);

            .aggregation-content {
                position: relative;
                padding: 0 2rem;
                width: 98%;
                height: 100%;
                justify-content: space-between;

                .relation_header {
                    &-title {
                        font-size: 1.5rem;
                        font-weight: 700;
                    }

                    .label {
                        font-weight: 700;
                        padding-bottom: 0.5rem;
                        color: var(--primaryDarkGray);
                    }

                }

                .margin-top {
                    margin-top: 2rem;
                }

                .margin-bottom {
                    margin-bottom: 2rem;
                }

                .flex {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: calc(100% - 1.40em) !important;
                    margin: 10px 0;

                    .label span {
                        font-style: italic;
                    }

                    .matches-with {
                        font-size: 1.7rem;
                        margin: 10px 0;
                    }

                    .subtitle {
                        color: var(--darkGraySubtitle);
                        font-weight: 500;
                        padding-left: 0.5rem;
                    }

                    .delete {
                        color: var(--primaryRed);
                    }
                }

                .field-container {
                    .field-content {
                        min-height: 26rem;
                    }

                    .label {
                        font-size: 0.875rem;
                        font-weight: 700;
                    }

                    .dropdown-field {
                        margin: 7px 0;

                        .warning {
                            display: inline-block;
                            font-size: 90%;
                            font-weight: 900;
                            color: var(--darkOrangeWarning);
                            margin-top: 5px;
                        }
                    }
                    .ui.dropdown.disabled {
                        cursor: not-allowed !important;
                        opacity: .85;
                        color: var(--grayText) !important;
                    }
                    .target-data-extension-dropdown {
                        width: calc(100% - 1.5em) !important;
                        min-width: 7rem !important;
                        border-radius: 0.25rem;

                        div.text,
                        input {
                            padding: 0px 1rem 0 0px;
                            width: 100% !important;
                        }

                        .menu {
                            max-height: 10rem;
                        }
                    }
                }
            }
        }
    }

    .swal_custom_bold {
        font-weight: bold;
    }

    .info_text {
        color: var(--grayText);
        font-size: 1rem;
        position: relative;
        margin-top: 0.5rem;
        line-height: 1.3;
    }

    .additional-join,
    .fields-container {
        border: .1rem solid var(--grayBorder);
        border-radius: .25rem;
        padding: 1em;
        width: calc(100% - 1.40em) !important;
        margin: 20px 0;
    }

    .add-relationship-btn-container {
        position: absolute;
        right: 30px;
    }

    .add-relationship-btn {
        position: relative;
        color: var(--primaryBlack);
        float: right;
        margin: 20px 20px 20px 0;

        .plus-icon {
            margin-right: 10px;
            color: var(--lightGrayBorder);
        }
    }

    .add-relationship-btn:disabled {
        color: var(--primaryGray);
    }

    .cancel-save-relation {
        position: sticky;
        bottom: 0;
        margin-bottom: 10px;
        width: 100%;
        bottom: 0;
        background: var(--lightGrayTableHeader);
        padding: 10px 2rem 1rem 2rem;
        border-top: solid 1px var(--lightGrayBorder);
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;

        .preview-loader-container {
            display: flex;
            position: relative;

            .slds-spinner {
                position: absolute;
                left: 0;
            }

            .when-pressed {
                padding-left: 1rem;
                font-style: italic;
            }
        }
    }
}
