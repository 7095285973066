#calendar-view-modal-dialog {

  .slds-modal__container {
    width: 40rem;
    max-width: 40rem;
  }

  .modal-container {
    padding: 1rem 2rem;
    height: 420px;
  }

  .schedule-description {
    text-align: left;
  }

  .next-run-date {
    margin-top: 5px;
    text-align: left;
  }

  .main-content {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 10px;
    height: 300px;
  }

    .loading-text {
      position: absolute;
      top: 60%;
      left: 37%;
      font-size: 1.2rem;
    }

  .scheduled-times-container, .scheduled-selections-container {
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    flex-grow: 1;
    margin-left: 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    overflow: auto;

    .scheduled-date {
      border-bottom: 2px solid #c0c0c0;
      padding: 10px;
      text-align: center;
    }

    .scheduled-selections-header {
      background-color: #EFEFEF;
      font-weight: bold;
      padding: 8px;
      margin-bottom: 3px;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #c0c0c0;
    }

    .scheduled-times, .scheduled-selections {
      overflow: scroll;
      text-align: center;
      height: 160px;
      flex-grow: 1;

      .scheduled-selection {
        padding: 10px;
        margin-top: 5px;
        color: #0174D9;
        font-weight: 500;
        text-align: left;
        word-break: break-word;

        &:not(:last-child) {
          border-bottom: 1px solid #c0c0c0;
        }

        &:hover {
          font-weight: 800;
          cursor: pointer;
        }
      }

      .scheduled-time {
        padding: 10px;
        margin-top: 5px;

        &:not(:last-child) {
          border-bottom: 1px solid #c0c0c0;
        }
      }
    }

    .buttons-container {
      display: flex;
      padding: 7px;
      margin-top: 10px;
      justify-content: flex-end;
      flex-flow: row;
      border-top: 1px solid #c0c0c0;
    }

    .close-scheduled-times {
      width: max-content;
      align-self: flex-end;
    }

    .show-scheduled-selections {
      width: max-content;
    }
  }
  // overried default disabled class style causing misalignment
  .rmdp-left.disabled {
    top: 54% !important;
    padding: 0 !important;
    background-color: transparent !important;
    cursor: not-allowed !important;
  }

  .calendar_notice{
    margin-top: 1em;
    text-align: center;
    font-style: italic;

    &__timezone{
      font-weight: bold;
      margin-top: 0.3em;
    }
  }
}
