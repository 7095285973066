.popupFolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  padding: 0.4rem;
  height: 86%;
  border-bottom: 1px solid var(--lightGrayBorder);

  ul {
    height: 100%;
    position: relative;
    left: 0;
  }

  &:not(:nth-child(1)) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 100%;
    transition: 0.15s;
    background-color: var(--white);
    z-index: 1;
  }

  &.open {
    left: 0%;
    top: 0%;
  }

  .selections-in-folder {
    padding: 0.2rem;
    border-radius: 0.3rem;
    font-size: 1.2rem;
    width: 98%;
    background-color: var(--white)!important;
    margin-top: 0.2rem;

    &:hover {
      background-color: var(--white)!important;
    }
  }

  .empty-folder {
    font-size: 1.2rem;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    padding: 0.2rem;
    border-radius: 0.3rem;
    font-size: 1.2rem;
    width: 98%;
    background-color: var(--mostlyWhiteBackground);
    margin-top: 0.2rem;

    &:hover {
      background-color: var(--darkenLightOrangeActive);
    }

    &.marked {
      background-color: var(--secondaryGreen);
    }

    .popupLine {
      display: flex;
      align-items: center;
      cursor: pointer !important;
      line-height: 1.5rem;

      &.active {
        background-color: var(--lightSoftBlue);
      }

      .arrowPopupFolder {
        cursor: pointer !important;
        transition: 0.15s;
        transform: translateY(-0.08rem);
        margin-left: auto;

        &:hover use {
          fill: var(--black);
        }

        svg,
        use {
          pointer-events: none;
          cursor: pointer !important;
          fill: var(--darkGrayText);
        }

        &.side {
          position: absolute;
          top: -1.7rem;
          left: 0.4rem;
          height: 1.8rem;
          width: 1.8rem;
        }
      }

      i {
        width: 1.6rem;
        padding: 0 0.4rem;
        cursor: pointer !important;
      }

      .slds-truncate span {
        padding: 0 .5rem;
        cursor: pointer;
      }
    }
  }
}
