.available-selection {
    margin-right: 1rem;

    .stickyAE {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 0%;
    }

    .available-selection-section {
        display: flex;
    }

    .section-title {
        white-space: nowrap;
        padding: 0.8rem 0;
        color: var(--blackTitle);
        border-radius: 0.2rem;
        font-weight: 600;
    }

    .overview_folders_wrapper {
      margin-top: 0;
      margin-left:0;
      margin-right: 0.3rem;
    }

    .overview_folders_wrapper-hide {
      left: 15.7rem;

      &.hide {
        left: -4rem
      }
    }

    .created-folders {
      height:calc(100vh - 11rem);
    }
}