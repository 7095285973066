#pagination {
  position: absolute;
  right: 0;

  ul {
    display: flex;
    margin-right: .3rem;

    li {
      margin: 0 .2rem;

      button {
        box-shadow: 1px 1px 2px 0 var(--blackOpacity15);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.7rem;
        width: 1.7rem;

        &:disabled {
          opacity: 0.6;
        }
      }

      .next-btn,
      .previous-btn {
        padding: 0;
      }
    }

    .pagination-select select {
      height: 1.7rem;
      display: flex;
      box-shadow: 1px 1px 2px 0 var(--blackOpacity15);
      border-radius: 0.3rem;
    }
  }
}

.pagination_container {
  padding: 0.2rem 0;
  width: 100%;
  height: 40px;
  position: relative;
  background-color: var(--lightGrayTableHeader);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .disabled {
    padding: 0;
  }
}