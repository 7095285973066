.source-limit-title {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;

  p {
    margin-top: 5px;
  }

  .slds-checkbox_toggle {
    width: 8%;
    margin-left: auto;

  }
}

.source-limit-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;

  &--disabled {
    opacity: 0.5;
  }

  .source-limit-input {
    border: 1px solid #000;
    border-radius: 0.3rem;
    background-color: #fff;
    outline: none;
    font-size: 14px;
    width: 60px;
    text-align: center;
  }

  .source-limit-dropdown {
    min-width: auto !important;
    width: 60px !important;
    border: 1px solid var(--darkGrayButtonBorder) !important;
    height: 1.5rem !important;

    .dropdown.icon {
      height: 1.9rem !important;
      top: 6px !important;
    }

    div.text{
      position: relative;
      top: -4px;
    }
  }

  .source-limit-dropdown::after{
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 1px;
    background: var(--darkGrayButtonBorder)
  }

  .slds-checkbox_toggle {
    width: auto;
    margin-left: auto;
  }

  .bold-text {
    font-weight: bold;
  }

  .source-limit-record-text{
    overflow-wrap: anywhere;
  }
}

#source-limiting-modal{
  .slds-modal__content{
    padding-bottom: 4rem ;
  }
}
