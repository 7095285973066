.deedee-ai-extensions-container {
  position: relative;
  height: 100%;
  .deedee-ai-data-extensions {
    background-color: var(--white);
    height: calc(100% - 120px);
    border-radius: 0.3rem;
    padding: 1.2rem 1.5rem;
    line-height: 1.2;
    overflow-y: scroll;
  }

  .deedee-ai-data-extensions-actions {
    display: block;
    position: relative;
    bottom: 12px;
    background-color: var(--modalGrayBackground);
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    width: 100%;
    height: 40px;
     .save-btn, .cancel-btn {
      float: right;
      margin-right: 2em;
    }
  }
}

.scope-de-width_swal{
  padding: 0 !important;
}

.scope-missing-fields-width_swal {
  padding: 0 !important;
  max-height: 350px;
  overflow-y: auto;
}


.scope-missing-fields-li{
  text-align: left;
  list-style: disc;
}
