.prio-dedup_container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--white);
  border-radius: 0.3rem;

  .prio-dedup-title {
    position: absolute;
    margin-top: 1.8rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  #advanced-prio-dedup-add-rule-btn{
    margin: 1rem 0;
  }

  .back-to-target-definition {
    position: absolute;
    left: 10%;
    margin-top: 1.8rem;

    i {
      margin-right: .3rem;
    }
  }

  .prio-dedup_wrapper {
    display: flex;
    margin-top: 5rem;
    width: 80%;
    border: 1px solid var(--grayBorder);
    border-radius: 0.25rem;
    box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
    padding: 2rem 2rem 5rem 2rem;
    min-height: 30rem;
    margin-bottom: 2rem;
    min-height: calc(100vh - 216px);
    position: relative;
    flex-direction: column;
    align-items: center;

    .prio-dedup-onoff {
      width: 16rem;
      align-self: flex-start;
    }

    .slds-progress {
      display: block;
      margin: 1rem 0 1rem 0;
      width: 70%;
      max-width: 20%;
      flex: 1;
      max-height: 2rem;
      position: absolute;
      right: 1rem;
      top: 3.4rem;

      .slds-progress__list {
        .slds-progress__item {
          position: relative;

          .tooltip-step-1 {
            position: absolute;
            top: -3.5rem;
            left: -1rem;
            width: 5.8rem;
          }
          .tooltip-step-2  {
            position: absolute;
            top: -3.93rem;
            left: -1rem;
            width: 5.8rem;
          }
        }
      }

      .slds-progress-bar {
        span {
          transition: 1s all;
        }
      }
    }

    .prio-dedup-page-1,
    .prio-dedup-page-2 {
      margin-top: 1rem;
      min-width: 24rem;
      max-width: 24rem;
      min-height: 19rem;

      .prio-dedup-page-title {
        font-weight: 500;
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 1.4rem;
      }

      .prio-dedup-element {
        margin-top: 1rem;
      }
    }

    .prio-dedup-page-2 {
      .define-sort-box {
        border: 1px solid var(--grayBorder);
        padding: 0.5rem;
        border-radius: 0.3rem;
      }

      .sort-way-box {
        legend {
          margin-bottom: 0.4rem;
        }

        p {
          margin-top: 0.3rem;
          font-size: 0.9rem;
          line-height: .9rem;


          span {
            font-weight: 700;
            margin-left: 0.25rem;
          }
        }
      }
    }

    .cancel-save-prio-dedup {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .prio-dedup-grayed-out {
    opacity: 0.4;
    pointer-events: none;
  }

  .slds-progress__marker {
    width: 1rem;
    height: 1rem;
  }

  .slds-button__icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
