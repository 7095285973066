.selection_criteriaNavigation {
  display: flex;
  grid-column: 6/19;
  transform: translateX(1rem);

  .slds-card {
    box-shadow: none;
    border: none;
    .slds-card__body {
      margin: 0;
      padding: .3rem 0;
      height: 2.7rem;
      .slds-path {
        .slds-grid {
          .slds-path__scroller-container {
            .slds-path__scroller {
              .slds-path__scroller_inner {
                display: flex;
                .slds-path__nav {
                  li {
                    width: 10.5rem;
                    line-height: 2rem;

                    &.opacity-for-disable {
                      opacity: .5;
                      background: var(--lightGreyedOut);

                      a {
                        background: var(--lightGreyedOut);
                        cursor: default;
                      }

                      &:hover {
                        background: var(--lightGreyedOut);

                        a {
                          background: var(--lightGreyedOut);
                        }
                      }

                      &::before, &::after {
                        background: var(--lightGreyedOut);
                      }
                    }
                  }
                  .guidance-tip-container{
                    position: absolute;
                    top: 2.3em;
                  }

                 .guidance-parent{
                    &-selection-criteria{
                      .guidance-tip-container{
                        left: 8em;
                      }
                    }

                    &-target-definition{
                      .guidance-tip-container{
                        left: 19em;
                      }
                    }

                    &-preview{
                      .guidance-tip-container{
                        left: 30em;
                      }
                    }
                 }
                }
                .slds-path__scroll-controls {
                  margin-left: .5rem;
                  transform: translateY(1px) scale(0.96);
                  display: flex;
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
  }



  &_button {
    margin: 0px 0.1rem;

    i {
      margin-right: .5rem;
    }

    .active {
      background-color: var(--darkenLightOrangeActive) !important;
      border: 1px solid var(--darkenLightOrangeActive) !important;

      &:hover {
        background-color: var(--darkenLightOrangeActiveHover) !important;
        border: 1px solid var(--darkenLightOrangeActiveHover) !important;
      }
    }

    .slds-button_success:hover {
      background-color: var(--greenActive);
      color: var(--black);
      border-color: var(--greenActive);
    }

    .disable-mouse-events {
      pointer-events: none !important;
      background-color: var(--disabledGray) !important;
      border-color: var(--disabledGray) !important;
      color: var(--white) !important;
    }
  }

  .slds-path__item {
    .slds-path__link:focus {
      text-decoration: none;
    }

    &.selection-navigator-steps, &.selection-navigator-runDetails, &.selection-navigator-selectionCriteria,
    &.selection-navigator-targetDefinition, &.slds-is-complete {
      .slds-path__title {
        transform: none;
      }

      &.selected {
        &.slds-is-complete:before, &.slds-is-complete:after, &.slds-is-complete {
          background:var(--primaryGreen);
        }
      }
    }
  }
}

.btn-active {
  background-color: var(--darkBlue)!important;

  &:hover {
       background-color: var(--darkBlueHover)!important;
  }
}
.target-def-btn-active {
  &::before, &::after {
      background-color: var(--darkBlue)!important;
    }
}

.run-preview-btn-active {
  background-color: var(--darkBlue)!important;

  &::before, &::after {
      background-color: var(--darkBlue)!important;
    }
    &:hover {
      background-color: var(--darkBlueHover)!important;

      &::before, &::after {
        background-color: var(--darkBlueHover)!important;
      }
  }
}
