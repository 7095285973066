.slds-button {
  margin: 0;

  &.set-dark-color {
    background-color: var(--darkGrayButton);
    color: var(--white);
    border-color: var(--darkGrayButtonBorder);

    &:hover:not(:disabled) {
      background-color: var(--darkGrayButtonHover) !important;
    }

    &:disabled {
      background-color: var(--darkGrayButtonDisabled);
    }
  }

  .when-pressed {
    padding-left: 1rem;
  }

  .slds-spinner {
    position: relative;
    top: 1rem;
    left: 0;
  }
}

.button-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
}