.tag-input-container {
  /* Styles for the input */
  .react-tagsinput-input {
    margin: 0;
    background-color: var(--white);
    border: 1px solid var(--lightGrayBorder);
    border-radius: 0.25rem;
    width: 100%;
    -webkit-transition: border 0.1s linear, background-color 0.1s linear;
    transition: border 0.1s linear, background-color 0.1s linear;
    display: block;
    padding: 0 1rem 0 0.75rem;
    line-height: 1.875rem;
    min-height: calc(1.875rem + (1px * 2));
  }
  /* Styles for the tag */
  .slds-pill {
    background: var(--primaryLightGray);
    color: var(--primaryBlack);
    font-size: 15px;
    margin: 1px !important;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    line-height: 1.5;
    max-width: 100%;
    padding: 0.125rem;
    border: 1px solid var(--lightGrayBorder);
    border-radius: 0.25rem;
    position: relative;
    min-height: 1.625rem;

    .slds-icon {
      color: var(--primaryBlack);
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 0.875rem;
      height: 0.875rem;
    }

    .slds-pill__label {
      margin-top: 0;
    }
  }
}