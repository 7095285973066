#archive-selection-modal-dialog {
    .slds-modal__container {
        width: 28rem;
    }

    header {
        padding: 0rem;
        border-bottom: none;
    }

    .archive-selection-message {
        padding: 15px;
        text-align: left;
    }

    #schedule-selection-header {
        padding: 1rem;

        h2 {
            font-weight: 300;
            font-size: 1.25rem;
            line-height: 1.25;
        }
    }

    .modal-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 3rem;
        justify-content: center;
    }

    .without-dedup {
        min-height: 15.5rem;
    }

    .with-dedup {
        min-height: 18.5rem;
    }

    .without-targetDE {
        min-height: auto;
    }

    .without-options {
        min-height: 9.5rem;
        display: flex;
        text-align: center;
    }

    .question-title {
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        line-height: 1.25;
        overflow-wrap: break-word;
    }

    .not-bold-warning-text {
        font-weight: normal;
        margin-bottom: 1rem;
    }

    .selection-archive-title-wrapper {
        margin-top: 1rem;
        display: flex;
    }

    .target-DE {
        line-height: 1.5;
        margin-bottom: 0.5rem;
    }

    .checkbox-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
        align-items: center;
    }

    .preview-loader-container {
        padding-top: 1.5rem;
        margin-left: 2.5rem;

        .slds-spinner {
            position: relative;
            left: 0;
            top: -0.65rem;
        }
    }

    .checkbox {
        width: 1rem;
        height: 1rem;
    }

    .title-target-DE {
        margin-left: 1rem;
        cursor: default;

        &.active {
            color: var(--primaryBlack)
        }

        &.disabled {
            color: var(--disabledGray);
        }
    }

    .DE-name {
        margin-left: 2rem;
        font-style: italic;
        cursor: default;
    }

    .tooltip-container {
        position: absolute;
        transform: translateY(-0.1rem);
    }

    .archive-selection-tooltip {
        transform: translateY(-2.3rem);
        line-height: 1.3;
    }

    .dedup-de-name {
        margin-left: 2rem;
        cursor: default;
    }

    .folderPopup {
        height: 25rem;
        width: 25rem;
        border: 1px solid var(--lightGrayBorder);
        background-color: var(--white);
        border-radius: 0.3rem;
        position: absolute;
        display: none;
        left: 17rem;
        top: 3rem;
        z-index: 100;
        overflow: hidden;
        padding-top: 2.2rem;
        box-shadow: 0 1px 2px 0 var(--primaryBoxShadowGray);

        &.active {
            display: block;
        }

        .currentFolderIn {
            position: absolute;
            top: 0.6rem;
        }

        #get-parent-folders {
            margin-right: 10px;
            margin-left: 6px;
            transition-duration: 0.4s;
            cursor: pointer;
            background-color: var(--white);
            color: var(--black);

            &:hover {
                background-color: var(--lightGrayScrollTrack);
            }
        }

        .makeSpaceFolder {
            height: 100%;
            width: 100%;
            position: relative;
            border-top: 1px solid var(--lightGrayBorder);
        }

        .closeFolderPopup {
            position: absolute;
            right: 0.5rem;
            top: 0.55rem;
            z-index: 200;
            cursor: pointer;

            &:hover use {
                fill: var(--black);
            }

            svg,
            use {
                pointer-events: none;
                fill: var(--primaryDarkGray);
            }
        }

        .confirmPopupButton {
            position: absolute;
            bottom: .5rem;
            right: .5rem;
            z-index: 1;
        }

        .selectedFolder {
            position: absolute;
            bottom: .9rem;
            left: .8rem;
            z-index: 1;
            font-size: 1.2rem;
            width: 17rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 1.4rem;
        }
    }

    #folder-popup-id {
        position: fixed !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .selected-folder-name {
        margin-left: 0.1rem;
        font-size: 1em;
    }

    .restore-selection-folder {
        margin-top: 0.5rem;
    }

    .restore-location-title {
        font-weight: bold;
        color: var(--primaryBlack);
    }

    .restore-selection-folder-container {
        padding-left: 1rem;
        padding-bottom: 10px;
    }

    .change-location {
        margin-left: 2rem;
        color: var(--primaryBlue);
    }

    .change-folder-popup {
        cursor: pointer;
    }
    
    .modal-header {
        border-bottom: 2px solid var(--lightGrayBorder);
        background-color: var(--white);
        .h4 {
            padding-left: 2.1rem;
        }
    }
    .restore-folder-icon {
        margin-top: -0.15rem;
    }
}