.adminpanel {
    &_header {
      margin-right: var(--rightbarWidth); // Needed for the rightnav
      background-color: transparent;
      padding: 0.75rem 0;
    }

    &_wrapper {
      border-radius: .3rem;
      justify-content: center;
      align-items: stretch;
      margin: 0 0.85%;
      background-color: var(--white);
      padding: .35rem 1rem;
      box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
      align-items: center;

      &_title {
        color: var(--darkGrayHeader);
        font-size: 2rem;
        font-weight: 500;
        display: flex;
        margin: 0.50rem 0;
      }
    }
}