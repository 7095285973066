.transform-date-modal {

    .tooltip-container{
        display: flex;
    }

    .custom-values-input {
        margin: 0 2rem 1rem 0;
    }

    .transform-date-options {
        display: flex;

        .slds-radio {
            margin-top: 0.2rem !important;
        }
    }

    .format {
        display: flex;
        margin-bottom: 1rem;
    }

    .title {
        font-weight: 600;
        display: block;
        margin-bottom: 7px;
        text-align: left;
    }

    .description {
        font-size: 14px;
        font-weight: normal;
        color: var(--grayText);
    }

    .data-extension,
    .field,
    .add {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }

    .add {
        margin-top: 10px;
    }

    .data-extension select {
        line-height: normal;
    }

    .form-element-label {
        flex-basis: 30%;
    }

    .form-control-elements {
        display: flex;
        width: 100%;
        justify-content: flex-start;

        .slds-input {
            padding-right: 0;
            margin-right: 10px;
            width: 100px;
        }

        #time-unit {
            width: 100px;
        }
    }

    fieldset {
        legend {
            margin-bottom: 5px;
        }

        .slds-radio {
            margin-left: 15px;
        }
    }

    .timezone-dropdowns-container {

        .timezone-dropdown-container {
            display: flex;
            align-items: center;
            margin-top: 10px;

            span {
                display: block;
                flex-basis: 35%;
            }

            .timezone-dropdown {
                flex-grow: 1;
            }

            .convert-to-timezone-transform {
                flex-basis: 65%;
            }
        }

        #convertFromTimezoneLabel {
            display: flex;
        }

        .timezone-dropdown-container-from {
            display: flex;
            align-items: center;
            .label-container-from{
                flex-basis: 35%;
            }
            .timezone-dropdown-tooltip-container{
                flex-basis: 65%;
            }
        }

        .tooltip-container {
            .slds-nubbin_bottom-right {
                top: -3.9rem;
            }
        }
    }

    .fit-content {
        width: fit-content;
    }
}
