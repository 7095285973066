.slds-progress-bar__value {
  width: 100%;

  &.fifty-width {
    width: 50%;
  }

  &.zero-width {
    width: 0%;
  }
}

.tooltip-step-1 {
  &.left-top-tooltip-step-1-position {
    left: -.8rem;
    top: -3.3rem
  }
}

.tooltip-step-2 {
  &.left-top-tooltip-step-2-position {
    left: -.8rem;
    top: -3.8rem
  }
}


.tooltip-one, .tooltip-two, .tooltip-three,
.tooltip-four {
  .slds-nubbin_bottom-left {
    left: -.8rem;
    top: -3.5rem;
  }

  &.nubbin-position-change {
    .slds-nubbin_bottom-left {
      left: -1rem;
      top: -3.7rem;
    }
  }
}

.tooltip-five {
  .slds-nubbin_bottom-left {
    left: -1rem;
    top: -3.7rem;
  }

  &.nubbin-position-change {
    .slds-nubbin_bottom-left {
      left: -.8rem;
      top: -3.5rem;
      background-color: #4bca81;
    }
  }
}

.tooltip-two, .tooltip-three, .tooltip-four, .tooltip-five {
  transition: 1s all;
  opacity: 0;

  &.show-tooltip {
    opacity: 1;
  }
}

.slds-popover_tooltip-bottom {
  width: 11em;
  background: white;
  margin-top: -2.9rem;
  margin-left: -11rem;
  display: flex;
}

.slds-bottomtooltip {
  color: black !important;
  font-size: 0.75rem !important;
}

.slds-popover_tooltip-bottom_dup {
  width: 11em;
  background: white;
  margin-top: -2.9rem;
  margin-left: -3rem;
  display: flex;
}

.slds-icon-new_window {
  height: 0.8em;
  width: 0.8em;
  fill: var(--darkGrayButtonBorder) !important;
  cursor: pointer;
  margin-top:0.4em;
}

.slds-link-size {
  width: 10%;
  z-index: 9;
}

.unknown-number-records{
  display: none;
}
