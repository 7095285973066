.active-users-component {
    margin: 0 auto 5px;
    width: 98.3%;
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.2);
    padding: 0 5px;
    padding-left: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 40px;
    border-radius: 0.3rem;
    margin-bottom: 0;
    position: relative;
    top: -0.37rem;
}

.fa-exclamation-triangle {
    margin: auto 10px auto 0;
}

.active-users-statement {
    font-size: 16px;
    font-weight: bold;
    margin: auto;
    flex: 1;
}

.active-users-list {
    flex: 1;
    margin-left: 20px;
    max-width: 35%;
    position: relative;
    overflow: hidden;
}

.active-users {
    padding: 0 10px;
    margin: auto 0;
    display: flex;
}

.active-users-minimalMode {
    float: right;
    position: absolute;
    margin: 5px 20px 0px 0px;
    right: 0;
    transition: right 0.2s;
    transition-delay: 0.2s;
}

.active-users-minimalMode.expanded {
    right: -60px;
    transition-delay: 0s;
}

.active-users-list .fa-users,
.active-users-list .fa-user-friends {
    padding: 8px 2px;
    font-size: 20px;
    cursor: pointer;
}

.active-users-number {
    position: absolute;
    background: #fff;
    top: -4px;
    left: 20px;
    padding: 2px 5px;
    border-radius: 100%;
    font-size: 13px;
}

.close-btn {
    border-left: 1px solid #706e6b;
    float: right;
    font-size: 17px;
    padding: 5px 8px;
    cursor: pointer;
    margin: auto;
    color: #706e6b;
}

.active-user {
    border: 3px solid #000;
    float: right;
    border-radius: 100%;
    padding: 6px 3px;
    min-width: 30px;
    text-align: center;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    font-size: 12px;
}

#active-users-list-normalMode {
    float: right;
    padding: 3px 8px;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#active-users-list-normalMode::-webkit-scrollbar {
    display: none;
}

.active-users-normalMode {
    background: #fff;
    max-width: 100%;
    float: right;
    border-radius: 5px;
    display: flex;
    width: 0%;
    overflow-x: hidden;
    border: 0px solid #706e6b;
    transition: width 0.2s ease-in, border-width 0.2s;
    transition-delay: 0s;
}

.active-users-normalMode.expanded {
    width: 100%;
    border-width: 1px;
    transition-delay: 0.2s;
}

.fa-caret-right {
    float: right;
}

.fa-caret-left {
    float: left;
}

.fa-caret-right,
.fa-caret-left {
    font-size: 20px;
    margin: auto 5px;
    cursor: pointer;
    color: #706e6b;
    padding: 0px 5px;
}

@media (prefers-reduced-motion) {

    .active-users-normalMode,
    .active-users-minimalMode {
        transition: none;
    }
}