.selectedTF-info {
    display: flex;
    justify-content: flex-end;
    width: 101.3%;

    .box-STF-icons {
        display: flex;
        align-items: center;
        position: relative;

        .automap {
            margin-right: .2rem;
        }

        & * {
            button {
                border-radius: .25rem !important;
            }
        }

        .slds-dropdown_actions {
            position: absolute;
            display: none;

            &.active {
                display: block;
                top: 2rem;
            }
        }
    }

    .toast-position {
        position: relative !important;
        transform-origin: top;
    }

    .toast-mapped {
        position: relative;
        z-index: 1;
        z-index: 999;
        opacity: 1;
        transform: translate(0%, 0%) rotateX(0deg);
        transition: .3s transfrom, .5s ;
    }

    .toast-none {
        opacity: 0;
        transform: translate(0%, -100%) rotateX(180deg);
        z-index: -999;
    }

    .slds-button_icon-inverse {
        transform: translate(1rem,-0.4rem) !important;
        width: 3.5rem;
    }

    .slds-icon-utility-success {
        margin: 0 0.75rem 0 0;
        align-self: center;
    }

    .slds-notify_toast{
        height: 2rem;
        padding: 0;
    }
}

.selectedTF-field_names {
    display: flex;
    justify-content: space-between;
    padding: .8rem 0;
    color: var(--black);
    border-radius: .2rem;
    margin: .2rem;
    margin-top: 1rem;
    font-weight: 600;
    transform: translateX(1rem);

    div {
        width: 50%;
    }
    div:nth-child(2){
        transform:translateX(1rem);
    }
}

.selectedTF-fields {
    border: 1px solid var(--grayBorder);
    border-radius:.25rem;
    box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
    transform: translateX(1rem);

    .target-collection-row {
        display: flex;
        margin: .6rem;
        border: .1rem solid var(--grayBorder);
        justify-content: center;
        align-items: center;
        border-radius: .25rem;

        .trash {
            color: var(--primaryRed);
        }

        .target-collection-dropzone {
            width: 100%;
            padding: .6rem;
            background-color: var(--dropzoneLightGrayBackground);
            box-shadow: inset 0 0 8px var(--blackBoxShadow);
            height: 3rem;
            display: flex;
            align-items: center;
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
            border: .1rem solid var(--dropzoneLightGrayBorder);
            transition: .3s all;
            position: relative;

            .empty-for-dropzone {
                width: 100%;
                height: 102%;
                position: absolute;
                left: 0px;
                top: 0px;
                background: transparent;
            }

            .drag-field {
                background-color: var(--white);
                box-shadow: inset 0 0 8px var(--blackBoxShadow);
                width: 100%;
                z-index: 99;
                position: absolute;
                top: -5.5px;
                left: -6px;
                height: 111%;
                width: 101%;
                display: flex;
                border-radius: 0px;
                cursor: default;
                justify-content: flex-start;

                &:hover {
                    background-color: var(--white) !important;
                    color: var(--blackLabel);
                }

                &_wrapper {
                    background-color: var(--white);
                    height: 123%;
                    border-radius: .3rem;
                    padding-left: .5rem;
                    transform: translateY(-.2rem);
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: default;

                    button {
                        margin-left: 1rem;
                    }
                }

                div {
                    .field-label {
                        padding-left: .5rem;
                        line-height: 2rem;
                        text-align: left;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .target-collection-field {
            width: 100%;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .span-target-de-field-name {
                flex: 1;
            }

            .span-field-info-button {
                min-width: 3rem;
                margin-right: 19px;

                &.with-2-icons {
                    margin-right: 8px;
                }

                span {
                    margin-right: .3rem;
                    transform: translateY(-.09rem);
                    cursor: pointer;
                        svg {
                            &:hover {
                            color: var(--lightOrangeBorder);
                        }
                    }
                }

                i {
                    margin-right:.3rem;
                    &.primary-key-icon {
                        color: var(--yellowOrangePrimaryKey);
                        cursor: default;
                    }

                    &.encrypted-lock-icon {
                        color: var(--primaryOrange);
                        cursor: default;
                    }

                    &.edit-info-icon {
                        &:hover {
                            color: var(--primaryOrange);
                        }
                    }
                }
            }

            .status-warning {
                color: var(--primaryOrange);
                cursor: pointer;
            }

            .status-error {
                color: var(--primaryRed);
                cursor: pointer;
            }
        }
    }
}

// new one
.target-de-fields-dropzone {
    text-align: center;
    line-height: 5rem;
    height: 5.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: var(--grayBorder);
    text-transform: uppercase;
}

.edit-target-field-icon {
    display: inline;
    margin-left: 7px;
    margin-right: 7px;
}

.target-de-update-fields {
    width: 24rem!important;
    padding-top: 3px;
}

.delete-matched-fields {
    width: 3.8rem;
}

.col-md-11 {
    margin-top: 8px;
}
.disable-div {
    pointer-events: none;
    opacity: 0.4;
  }

// swal content
.swalContent {
    display: flex;
    align-items: center;
}

// field-info-row
.field-info-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
    min-height: 28px;

    &-left {
        width: 9rem;
        text-align: left;
        margin-top: .26rem;
    }

    &-right {
        width: 11rem;
        text-align: left;
        margin-top: .26rem;
        .slds-form-element {
            .slds-form-element__control {
                input {
                    width: 11rem;
                }
            }
        }
    }
}

.overwrite-warning {
    border-radius: .5rem;
    padding: 1.2rem;
    background-color: #c4e9f7;
    margin: 1rem;
    font-size: 15px;
.input-date-looks-like-LDS {
    background-color: var(--white);
    border: 1px solid var(--lightGrayBorder);
    border-radius: 0.25rem;
    width: 100%;
    -webkit-transition: border 0.1s linear, background-color 0.1s linear;
    transition: border 0.1s linear, background-color 0.1s linear;
    display: inline-block;
    padding: 0 .1rem 0 0.75rem;

    &:active, &:focus {
        outline: 0;
        border-color: var(--focusBlue);
        background-color: var(--white);
        -webkit-box-shadow: 0 0 3px var(--primaryBlue);
        box-shadow: 0 0 3px var(--primaryBlue);
    }
}
}

.disable-data-action-radio-container {
    opacity: 0.4;
}

.warning-data-action {
    transform: translate(1rem, 1rem);
    margin: -0.1rem 0 0.5rem 0;

    .change-data-action {
        color: var(--darkenBlue) !important;
        &:hover {
            text-decoration: underline !important;
        }
    }
}

.indication-message {
    transform: translate(1rem, 1rem);
    justify-content: center;
    margin: -0.1rem 0 0.5rem 0;
}

.target-de-group-button {
    margin-right: 2px;
    margin-left: 4px;
}

.pointer-event-none {
    pointer-events: none;
}

.targetDE-automap-confirm {

    &::after {
        width: 16px !important;
        height: 16px !important;
        margin-left: 0.4rem !important;
        margin-top: 0.3rem !important;
        border: 3px solid var(--white) !important;
        border-right-color: transparent !important;
    }
}

.automap-spinner {
    position: absolute !important;
    left: 1rem;
    top: 0;

    .slds-spinner__dot-b, .slds-spinner__dot-a  {
        transform: rotate(60deg);
        transform-origin: 50% 0%;
    }
}

#map-button {
    padding-left: 2.2rem;
    height:2.25rem !important;
    line-height: 1.875rem;
}

.mapping-loader {
    align-items: center;
    justify-content: center;
    width: fit-content !important;
    width: -moz-fit-content !important;
    height: 0em !important;
    margin:  0em !important;
    -webkit-animation: none !important;
    animation: none !important;
    border-width: 0 !important;
}

.targetDE-automap-cancel {

    &:disabled {
        background:var(--disabledGray) !important;
        border-color:var(--disabledGray) !important;
        color:var(--white) !important;
    }
}