.selection-template{
  &.disabled-overlay{
    padding: 5px;
    background-color: var(--disabledOverlayOrange);
    cursor: pointer;
    .slds-checkbox_toggle, .checkbox-label{
      pointer-events: none;
      opacity: 0.5;
    }
    .tooltip-container{
      position: absolute;
      left: 8.6em;
      bottom: 5em;
    }
  }
}
.use-template-toggle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.1rem;
  padding-bottom: 0;

  .upgrade-badge-icon{
    left: 8.7em;
    bottom: 4.8em;
    z-index: 99;
  }

  #toggle-button-label {
    width: fit-content;
  }

  .title-template {
    margin-left: 1rem;
  }

  .checkbox-label {
    font-weight: bold;
  }

  .disabled {
    color: var(--darkGrayButtonDisabled);
  }
}

.template-selection-dropdown {
  width: 100% !important;
  max-height: 3rem !important;
  line-height: .27rem !important;
  margin: 0 !important;
  min-height: 0px !important;
  height: 2rem !important;
  padding: .5rem !important;

  div[role="listbox"] {
    position: relative !important;
    max-height: 150px !important;
    margin-left: -6px !important;
    margin-top: -6px !important;
    min-width: calc(100% + 13px ) !important;
    width: calc(100% + 13px ) !important;
  }


  input {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.9rem !important;
    padding-left: 1rem !important;
    height: 100% !important;
    width: 25rem !important;
  }

  div.text {
    width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1rem;
    height: 1.2rem;
  }

  i {
    padding: .5rem !important;
  }

  .item {
    height: 2rem !important;
    line-height: .3rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    span {
      white-space: nowrap !important;
    }
  }

  .selected.item {
    font-weight: 400 !important;
  }
}

.location-wrapper-section {

  .location-title {
      font-weight: 600;
  }

  .location-container {
      display:flex;
      align-items: center;
      margin-top: 0.5rem;

      i {
          font-size: 18px;
          margin-right: 0.5rem;
          width: 1.3rem;
      }
  }

  .change-location-title {
      margin-left: 1rem;
      color: var(--primaryBlue);
      cursor: pointer;
      font-weight: 500;

      &:hover {
          text-decoration: underline;
      }
  }
}

.template-copy-container{
  margin-top: 1em;
  &__toggle{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.1rem;

    &__title{
      font-weight: 600;
      margin-top: 0.3em;
    }
    #toggle-button-label{
      width: fit-content;
    }
    .checkbox-label{
      font-weight: bold;
    }
    .disabled{
      color: var(--darkGrayButtonDisabled);
    }
  }
  &__input{
    display: flex;
    justify-content: space-between;
  }
  .no-target-DE-text {
    text-align: justify;
    line-height: 1.5;
    color: var(--darkGrayButtonDisabled);
  }

  .disabled-text{
    color: var(--darkGrayButtonDisabled);
  }
}

.spinner_container{
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}
