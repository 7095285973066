.available-fields {
  grid-column: 1/6;

  &.false,
  &.mt-2-per {
    width: 100%;
    max-width: 20vw;
    grid-row: 1;
    top: 0.5%;
    }


  &.mt-2-per {
    height: fit-content;
  }

  &.true{
    .upgrade-badge-icon{
      z-index: 9;
      bottom: auto;
      margin-top: -20px;
      display: block;
      left: 7em;
    }
    .unavailable-feature-tooltip{
      .tooltip-container{
        position: absolute;
        margin-top: -16px;
        display: block;
        left: 7em;
        z-index: 99;
      }
    }
  }

  .make-space-for-scroll {
    height: 15.2rem;
    border: 1px solid var(--grayBorder);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    .disabled-overlay-cv{
      background: var(--disabledOverlayOrange);
      position: absolute;
      width: 100%;
      cursor: pointer;
      height: 100%;
      height: -moz-available;          /* WebKit-based browsers will ignore this. */
      height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      height: fill-available;
    }
    &.disabled-cv{
      cursor: pointer;
      .tooltip-container{
        pointer-events: none;
        display: none;
      }
    }
  }

  .section-title {
    white-space: nowrap;
    padding: .8rem 0;
    color: var(--blackTitle);
    border-radius: .2rem;
    font-weight: 600;
    .guidance-tip-container{
      display: inline-block;
      position: absolute;
      top: 0;
      margin: 0;
    }
  }

  &_input_wrapper {
    display: flex;
    border: 1px solid var(--grayBorder);
    border-bottom: none;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-width: thin;
    overflow: hidden;

    .disabled-overlay-cv{
      background: var(--disabledOverlayOrange);
      position: absolute;
      width: 100%;
      height: 36px;
    }

    input {
      width: 100%;
      min-width: 0;
      padding: .2rem .2rem .2rem 1rem;
      outline: none;
      border: none;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;

      &::placeholder {
        color: var(--blackOpacity50);
      }
    }

    &_icon_wrapper {
      display: flex;
      border-top-right-radius: 0.25rem;
      border-left: 1px solid var(--darkenLightGrayBorder);

      span {
        padding: .7rem .8rem;
        display: flex;
        background-color: var(--lightGrayIconBackground);
        border: none;
        border-top-right-radius: .25rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .make-space-for-scroll {
    border: 1px solid var(--grayBorder);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    height: 100% !important;
    overflow-x: hidden;
  }

  &_available_collections {
    overflow-x: hidden;
    min-height: 15rem;
    max-height: 15rem;
  }
}

.disabled {
  background-color: var(--primaryLightGray);
  color: var(--blackTitle);
  padding: 0.5rem 0.4rem;
  display: flex;
  align-items: flex-start;
  position: -webkit-sticky;
  top: 0;
  cursor: default!important;
}

.h-17rem {
  height: 17.2rem!important;
}

.filter-sets-tab {
  display: flex;
}
.mr-4px {
  margin-right: 4px;
}
