.filter-set-modal-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 1em;
    padding-top: 0.5em;
}

.banner-container {
    padding: 0.5rem;
    cursor: grab;
    border-radius: 0.25rem;
    margin: 0.2rem;
    background-color: #D8EDFF;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}

.dropdown-title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.subscribers-container {
    display: flex;
}

.subtitle {
    margin-left: 0.5rem !important;
    margin-top: 0.4rem !important;
    margin-right: 0.5rem !important;
}

.target-data-extension-dropdown.searchable-dropdown.subscriber-info {
    width: 100% !important;
}

.target-data-extension-dropdown.searchable-dropdown.subscriber-relation {
    width: 18rem !important;
}

.selected-name {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

div#hasNoSelectedDataExtension {
    margin-top: 10px !important; 
    width: 100% !important;
}

div#hasNoSelectedField {
    margin-top: 10px !important;
}






 
