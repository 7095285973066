.drag-collection {
    padding: .5rem;
    border: 1px solid var(--lightGrayColdBorder);
    background-color: transparent;
    cursor: grab;
    margin: .2rem;
    border-radius: .25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover {
        background-color: var(--lightGrayishBlueHover) !important;
        color: var(--darkDesaturatedBlue);
    }

    &:active {
        cursor: grabbing;
    }
}

.dragged-collection {
    padding: .5rem .3rem;
    font-size: 1rem;
    display: inline-block;
    border: .1rem solid var(--gray);
    border-radius: .25rem;
    line-height: 1.3rem;
    width: calc(-1.5rem + 100%);
    width: -webkit-fill-available;
    width: -moz-available;
    position: relative;
    transition: .3s all;
    border-left: .1rem solid var(--darkGrayHeader);
    background-color: var(--white);
    box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
    cursor: move;

    &:hover {
        border-left: .4rem solid var(--primaryOrange);

        &::before {
            background-color:var(--primaryOrange);
            z-index: 1;
        }
    }

    &:not(:hover){
        border-left: .1rem solid var(--gray);

        &::before {
            background-color: var(--gray);
            left: -1.1rem;
            width: 1.05rem;
        }
    }

    span {
        border-radius: .25rem;
        cursor: pointer;
        &:hover {
            color: var(--darkenBlue);
            text-decoration: underline;
            font-weight: 500;
        }
    }

    .selected-de-span {
        white-space: nowrap;
        padding-left: 2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: block;

        &.parent_DE {
            margin-left: -1.4rem;
        }

        span, .no_alias {
            max-width: 47%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.no_alias {
                width: 100%;
            }
        }
    }

    &::before {
        transition: .3s all;
        content: "";
        display: block;
        position: absolute;
        left: -1.4rem;
        top: 2rem;
        height: .4rem;
        width: 1rem;
        background-color: var(--darkGrayHeader);
        z-index: -1;
        z-index: 9;
        pointer-events: none;
    }
}

.dragged-collection:nth-child(1)::before {
    display: none;
}

// Remove DE
.extension-remove-button {
    border: none;
    background: transparent;
    position: absolute;

    &.withoutPointer, i.withoutPointer {
        pointer-events: none;
    }

}

// Edit DE relations
.edit-relation-link {
    padding-bottom: .4rem;
    font-style: italic;
    padding-left: .5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .slds-icon_container {
        transform: translate(.1rem, -.1rem);
        color: var(--primaryBlack);

        &:hover {
            color: var(--primaryOrange);
        }
    }
}

.data-set-add-de {
    padding: 0.5rem 0.3rem;
    font-size: 1rem;
    display: inline-block;
    border: 0.1rem solid #a6a6a6;
    border-radius: 0.25rem;
    line-height: 1.3rem;
    width: calc(-1.5rem + 100%);
    width: -webkit-fill-available;
    width: -moz-available;
    transition: .3s all;
    border-left: 0.1rem solid #6e6e6e;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 #403d331f;
    cursor: pointer;
}

.selected-de-card-data-set {
    padding: .5rem .3rem;
    font-size: 1rem;
    display: inline-block;
    border: .1rem solid #a6a6a6;
    border-radius: .25rem;
    line-height: 1.3rem;
    width: calc(-1.5rem + 100%);
    width: -webkit-fill-available;
    width: -moz-available;
    position: relative;
    transition: .3s all;
    border-left: .1rem solid hsl(0, 0%, 43%);
    background-color: #fff;
    box-shadow: 0 2px 2px 0 #403d331f;

    &:hover {
        border-left: .4rem solid #FF9900;

        &::before {
            background-color:#FF9900;
            z-index: 1;
        }
    }

    &:not(:hover){
        border-left: .1rem solid hsl(0, 0%, 65%);
 
        &::before {
            background-color: hsl(0, 0%, 65%);
            left: -1.1rem;
            width: 1.05rem;
        }
    }

    span {
        border-radius: .25rem;
        &:hover {
            color: #02539b;
            text-decoration: underline;
            font-weight: 500;
        }
    }

    .selected-de-span {
        white-space: nowrap;
        padding-left: 2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: block;

        &.parent_DE {
            margin-left: -1.4rem;
        }

        span, .no_alias {
            max-width: 47%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.no_alias {
                width: 100%;
            }
        }
    }

    &::before {
        transition: .3s all;
        content: "";
        display: block;
        position: absolute;
        left: -1.4rem;
        top: 2rem;
        height: .4rem;
        width: 1rem;
        background-color: hsl(0, 0%, 43%);
        z-index: -1;
        z-index: 9;
        pointer-events: none;
    }
}

.selected-de-card-data-set:nth-child(1)::before {
    display: none;
}

.relate-data-extension-button {
    float: right;
    width: 38px;
    height: 0px;
    margin-top: -21px;

    button {
        border: none;
        color: #2E2E2E;
        background: #969492;
        margin: 0 auto;
        display: block;
        -webkit-transform: scale(0.48);
        transform: scale(0.48);
        border-radius: 50%;
        width: 41px;
        height: 38px;
        margin-top: -9px;
    }

    .fill-white {
        fill: white;
    }
}

.dashedBorder {
   border: var(--dashedBorder)
}

.no-dashedBorder {
    border: ''
}