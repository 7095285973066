.sidenav {
    height: calc(100vh - 93px);
    width: 15%;
    background-color: var(--white);
    overflow-x: hidden;
    margin-left: .85%;
    border-radius: .3rem;
    margin-right: 10px;
    text-align: center;
    flex: 1;
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);

    &>h1 {
        margin-left: .4rem;
        text-align: left;
    }
}

.AdminPanel {
    margin-right: var(--rightbarWidth); // Needed for the rightnav
    display: flex;
    margin-bottom: 1rem;

    .premium-icon {
        display: inline;
        width: 25px;
        margin-left: 5px;
    }

    .feature-unavailable {
        background-color: #fbdbc9af;
        opacity: 0.5;
        border: 1px solid var(--lightGrayBorder);
        &:hover {
            background-color: var(--lightOrange);
            opacity: 1;
        }
    }

    .relations-submenu.feature-unavailable{
        .feature-title-text{
            margin-top: 5px;
        }
    }

    .guidance-parent-admin-folders{
        display: inline-block;
        position: absolute;
        .guidance-tip-container{
            position: absolute;
            top: -0.75em;
            margin: 0;
            .slds-icon{
                fill: var(--white);
            }
        }
    }

    .guidance-parent-new-relation{
        position: relative;
       .guidance-tip-container{
        position: absolute;
        top: -2.2em;
        z-index: 2;
        right: 0.5em;
       }
    }
}

.main {
    height: calc(100vh - 93px);
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    flex: 5;
    margin-right: .85%;
    background-color: var(--lightBlue);
    width: 82.15%;
    border-radius: .3rem;
    overflow: auto;
    padding: 22px;
    padding-bottom: 0;
    background-image: url('/assets/images/banner-brand-default.png'),
        linear-gradient(to bottom, rgba(26, 85, 149, 1) 0%, rgba(176, 196, 223, 1) 100%);
}

.menu-option {
    text-align: left;
    border-left: 5px solid transparent;

    &:hover {
        background-color: var(--whiteSmoke);
        cursor: pointer;
    }
}

.relations {
    text-align: left;
    border-left: 5px solid transparent;

    .active {
        font-weight: 700;
    }

    .relations-submenu {
        display: block !important;
        padding-left: 1.5rem;
        margin-top: 10px;

        &:hover {
            font-weight: 500;
            color: var(--primaryOrange);
            cursor: pointer;
        }
    }

    .relation-submenu-disabled {
        color: var(--lightGrayDisabledItem);

        &:hover {
            color: var(--lightGrayDisabledItem);
            cursor: default;
        }
    }
}

.de-folders {
    text-align: left;
    border-left: 5px solid transparent;

    .active {
        font-weight: 700;
    }

    .de-folders-submenu {
        display: block !important;
        padding-left: 1.5rem;
        margin-top: 10px;

        &:hover {
            font-weight: 500;
            color: var(--primaryOrange);
            cursor: pointer;
        }
    }
}

.disabled-feature {
    display: flex;
    align-items: center !important;
}

.selected-option {
    background-color: var(--whiteSmoke);
    cursor: pointer;
    border-left: 5px solid var(--primaryOrange);
}

.backArrow {
    background-color: var(--whiteSmoke);
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        background-color: var(--lightOrange);
    }

    span {
        margin-left: 5px;
    }
}

.AdminPanel {
    .sidenav {
        .slds-icon {
            fill: var(--black);
        }
    }

    .disabled-navigation {
        color: var(--disabledGrayBlue);
        cursor: not-allowed;

        .backArrow {
            background-color: unset;

            &:hover {
                background-color: unset;
                cursor: not-allowed;
            }
        }


        .de-folders-submenu:hover {
            color: unset;
            cursor: not-allowed;
        }

        .menu-option:hover {
            background-color: unset;
            cursor: not-allowed;
        }

        .selected-option {
            background-color: unset;
            cursor: not-allowed;
        }
    }
}

.header-alignment {
    position: relative;

    h1 {
        position: absolute;
        top: 0.5rem;
    }
}

.admin-container {
    margin-right: var(--rightbarWidth); // Needed for the rightnav
    margin-bottom: 5px; //needed for the shadow
}