.aggregation-header-wrapper {
    &__title {
        font-weight: 600;
        font-size: 18px;
    }

    &__description {
        font-weight: normal;
        font-size: 15px;
        margin: 20px 0px;
        display: block;
        color: var(--grayText);
    }
}

.aggregation-modal {

    .formula-content,
    .field-content,
    .relation-fields-container,
    .relations-description-container,
    .matches-with-container {
        display: flex;

        .flex-title {
            flex-basis: 30%;
            align-items: start;
            margin-top: 0.5rem;
        }

        .dropdown-functions {
            flex-basis: 70%;

            .target-data-extension-dropdown {
                width: calc(100% - 0.75em) !important;
            }
        }
    }



    .label {
        font-weight: 600;
    }

    .field-content {
        .dropdown-functions {
            width: 35%;
        }
        .tooltip-container {
            bottom: 2px;
            margin-left: 2px;
        }
    }

    .relation-fields-container {
        .flex-title {
            flex-basis: 30%;
        }
        .tooltip-container {
            bottom: 2px;
            margin-left: 2px;
        }
    }
    .relation-content{
        .tooltip-container {
            font-weight: normal;
            margin-left: 2px;
        }
    }

    .relations-description-container {
        .dropdown-functions {
            display: flex;
            margin: 1rem 0 1rem 5px;

            .data-extension-name {
                font-weight: 600;
                margin: 0 5px 0 5px;
            }
        }
    }

    .matches-with-container {
        .flex-title {
            flex-basis: 30%;
        }

        .relations-to-field {
            margin-bottom: 0.5rem;
        }
    }

    .aggregation-container {
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: 15px;

        .aggregation-content {
            width: 100%;

            .label {
                padding-bottom: 0.5rem;
                color: var(--primaryDarkGray);
            }

            .margin-top {
                margin-top: 2rem;
            }

            .margin-bottom {
                margin-bottom: 2rem;
            }

            .flex {
                display: flex;
                flex-wrap: wrap;

                .subtitle {
                    color: var(--darkGraySubtitle);
                    font-weight: 500;
                    padding-left: 0.5rem;
                }
            }

            .field-container {
                .fields {
                    margin-top: 0.5rem;
                }

                .target-data-extension-dropdown {
                    width: calc(100% - 1.5em);
                    min-width: 7rem !important;
                    border-radius: 0.25rem;

                    div.text,
                    input {
                        padding: 0px 1rem 0 0px;
                        width: 100% !important;
                    }

                    .menu {
                        max-height: 10rem;
                    }
                }
            }

            .relation-container {

                .subtitle {
                    color: var(--darkGraySubtitle);
                    font-weight: 500;
                    padding-left: 0;
                }

                .flex {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                    padding-top: 0.5rem;

                    .dropdown-title {
                        padding: 0.5rem 1rem 0 0px;
                    }

                    .dropdown-field {
                        flex: 1;
                        max-width: calc(100% - 2em) !important;
                    }

                    .target-data-extension-dropdown {
                        width: calc(100% - 0.75em) !important;
                        border-radius: 0.25rem;

                        div.text,
                        input {
                            max-width: 100% !important;
                            padding: 0px 1rem 0 0px;
                        }

                        .menu {
                            max-height: 10rem;
                        }
                    }
                }

                .relations-flex>* {
                    flex: 1 0;
                    margin-bottom: 10px;
                }

                .dropdown-title {
                    flex: 0 0;
                }

                .relations-flex {
                    display: flex;
                    flex-wrap: wrap;
                    width: calc(100% - 0.70em) !important;

                    .target-data-extension-dropdown {
                        width: calc(100% - 0.70em) !important;
                        min-width: 10rem !important;
                        border-radius: 0.25rem;

                        div.text,
                        input {
                            width: 100% !important;
                            padding: 0px 1rem 0 0px;
                        }

                        .menu {
                            max-height: 8rem;
                        }
                    }

                    .dropdown-title {
                        padding: 0.5rem 1rem 0 0px;
                    }


                    .relations-fixed-width {
                        display: flex;
                        flex: none;
                    }

                    .flex-text {
                        display: flex;
                        width: 100%;

                        .data-extension-name {
                            height: 18px;
                            flex: 1;
                            padding: 0;
                            overflow: hidden;
                            position: relative;
                            display: inline-block;
                            margin: 0 5px 0 5px;
                            text-align: center;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: var(--black);
                            cursor: pointer;
                            font-weight: bold;
                        }
                    }
                }
            }

            .filters-container {
                margin-bottom: 2rem;

                .add-filters-btn {
                    padding-left: 0.5rem;
                    padding-right: 1.35rem;

                    .slds-button_neutral {
                        width: -webkit-max-content;
                        width: -moz-max-content;
                        width: max-content;
                    }
                }

                .edit-icon {
                    padding-left: 0.2rem;
                    padding-bottom: 0.1rem;
                }

                .filters-text {
                    padding-top: 0.5rem;
                    font-weight: 400;
                    width: 100%;
                    font-style: italic;
                }

                .filters-flex {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                }

                .filters-flex-title-main {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;

                    .label{
                        display: inline-block;
                        margin-top: 5px;
                    }

                    .filters-flex-subtitle {
                        color: var(--darkGraySubtitle);
                        font-weight: 500;
                        padding-left: 0.5rem;
                        display: flex;
                        flex-wrap: nowrap;
                        max-width: calc(100% - 4.5em) !important;

                        .data-extension-name {
                            padding: 0;
                            overflow: hidden;
                            display: inline-block;
                            margin: 0 5px 0 5px;
                            text-decoration: none;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            flex: 1;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

#aggregation-subquery-modal-dialog {
    left: -170%;
    right: -170%;

    .react-datepicker__input-container {
        width: 100% !important;
    }

    .subquery-dialog {
        display: none;

        &.show {
            display: block;
        }
    }
}

.visible {
    visibility: visible !important;
}

.aggregation-options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.9rem;

    .header-options {
        display: flex;
        border-bottom: 1px solid var(--lightGrayBorder);
        width: calc(100% - 1.8em) !important;
        font-size: 0.9rem;

        .aggregation-option {
            width: 8rem;
            text-align: center;
            padding: 10px;
            cursor: pointer;
            border: 1px solid var(--lightGrayBorder);

            &.active {
                background-color: var(--darkBlue);
                color: var(--white);
                border-color: var(--darkBlue);
                font-weight: 600;
            }
        }
    }
}

.basic-mode-modal-height {
    min-height: 18.5rem;
}

.basic-mode-modal-height-field {
    height: 24.5rem;
}

.subtitle-basic {
    color: var(--darkGraySubtitle);
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.field-mismatch-error {
    color: var(--errorRed);
    padding-bottom: 1em !important;
    font-size: 14px;
    margin-left: 30%;
}
