.tab-container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex-direction: row;
  width: 100%;

  .tab {
    font-size: 14px;
    padding: .6em;
    width: 50%;
    border-bottom: 0.5px solid;
    border-color: var(--grayBorder) !important;
    &.disabled-overlay{
      background-color: var(--disabledOverlayOrange) !important;
      border-right: 1px solid var(--grayBorder) !important;
      span{
        opacity: 0.5;
      }
    }
  }

  .active {
    background: #005fb2;
    color: white;
    border-color: #005fb2 !important;

      .slds-button__icon {
        fill: white;
      }
  }


  .disabled {
    cursor: default;
    border-color: gainsboro !important;
    background: gainsboro !important;
    color: black !important;
  }



  .upgrade-badge-icon {
    position: relative;
    top: -5px;
    right: 25px;
  }
}
