.related-records-container {
    grid-row: 2;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 20.5rem;
    width: 100% !important;

    .section-title {
        margin: 0;
    }

    .related-records-scroll {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        height: fit-content;

        #related-collections {
            border-radius: 0.3rem;
            min-height: auto !important;
            overflow-y: auto;
        }
    }
}