.insert-function-container {
    position:relative;
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid var(--lightGrayBorder);
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--white);
    z-index:2;
    margin-bottom:0.5rem;
    justify-content: space-between;
    min-height: 19rem;

    @media (max-width: 1480px) {
    min-height: 22rem;
    }

    .insert-function-content {
    display: flex;
    width:100%;
    align-items: flex-start;
    flex-direction: column;
    }

    .insert-function-title {
        font-weight: 700;
        padding-bottom:1rem;
        color: var(--primaryDarkGray);
    }

    .syntax-descriptions {
        line-height: 1.2;
        padding: 0.5rem 0 0.2rem 0;

        & div:first-child {
            padding-bottom: 0.2rem
        }
    }

    .syntax-example {
        line-height: 1.2;
        padding-bottom: 0.3rem;

        & div:first-child {
            padding-bottom: 0.2rem
        }
    }

    .custom-values-select {
        width:100% !important;
    }

    select {
        padding: 5px;
      }

    select,select option, optgroup {
        color: var(--black);
    }

    select:invalid, select option[value=""] {
        color: var(--darkBrownGray);
    }

    #insert-formula-button {
        width:fit-content;

        &:disabled {
            #plus-icon {
                color: var(--lightGrayBorder);
            }
        }

        #plus-icon {
          margin-left: 10px;
          color: var(--primaryBlue);
          width: 1.3rem;
          height: 1.3rem;
         }
      }
}