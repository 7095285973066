.prio-val-input {
    label {
        margin-bottom: .4rem;
        abbr {
            text-decoration: none !important;
        }
    }
    .slds-form-element__control {
        display: flex;
        align-items: center;

        input {
            margin-right: .6rem;
            width: 100%;
        }
    }
}

.value_input_boolean {
    margin-right: .6rem;
    width: 100%;
}