.selection-list-column {
  min-width: 23rem;

  .available-selection_input_wrapper {
    display: flex;
    border: 1px solid var(--grayBorder);
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-width: thin;
    overflow: hidden;

    .slds-form-element {
      width: 100%;
    }

    input {
      width: 100%;
      min-width: 0;
      padding: 0.2rem 0.2rem 0.2rem 1rem;
      border: none;
      border-top-left-radius: 0.25rem;

      &::placeholder {
        color: var(--black);
      }

      &:focus {
        outline-style: none;
        box-shadow: none;
      }
    }

    &_icon_wrapper {
      display: flex;
      border-top-right-radius: 0.25rem;
      border-left: 1px solid var(--darkenLightGrayBorder);

      span {
        padding: 0.7rem 0.8rem;
        display: flex;
        background-color: var(--lightGrayIconBackground);
        border: none;
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .make-space-for-scroll {
    height: calc(100vh - 13.8rem);
    border: 1px solid var(--grayBorder);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    position: relative;

    .pagination_container {
      background: transparent;
      position: absolute;
      bottom: 0;
    }
  }

  .available_selection_column {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 14rem);
  }

  .disabled-selection-information {
    position: absolute;
    display: flex;
    line-height: 2;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
      fill: var(--grayBorder);
    }
  }
}

.create-new-selection-div {
  text-align: end;
   padding: 5px;
}
