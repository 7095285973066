.selection {
    height: 100vh;
    margin-right: var(--rightbarWidth); // Needed for the rightnav
}

/* decrease the height of these classes if the active-users-component class exists  */
.selection .active-users-component + .dataExtension_multipleTabs,
.selection .active-users-component + .data-extension-single,
.selection .active-users-component + .preview,
.selection .active-users-component + .target-definition{
    height: calc(100vh - 140px);
}

.missing-fields-table {
    width: fit-content !important;
    max-width: 450px !important;

    .main-content-container {
        padding: 10px;

        tr {
            display: flex;
            max-width: 400px;
        }

        th, td {
            flex: 1;
        }
    }

    .swal-message {
        margin-bottom: 5px;
    }
}

.drag_drop_selection {
    .slds-button__icon {
        margin-bottom: .2rem;
    }
}

.swal2-validation-message {
    max-width: 27rem;
}

.Toastify__toast-container {
    padding: 0px;
    display:flex;
    min-width: 43rem;
    align-items: center;
    justify-content: flex-end;
    margin-right: 1rem;
    min-height: 2rem;

    &.Toastify__toast-container--top-right {
        top: 1em;
        right: 1em;
    }
}

.toast-autofix, .toast-saved, .toast-mapped, .toast-info {
    position: relative;
    background:var(--primaryGreen);
    min-height: 2rem;
    top: 0;


    .slds-notify_toast, .slds-notify--toast {
        padding: 0;
        margin: 0;
    }

    .Toastify__close-button {
        align-self: center;
        padding: 0.5rem;
    }

    &.Toastify__toast {
        padding: 0px;
    }
}

.toast-info {
    background: #706e6b;
    margin-right: 48px;
}

.toast-autofix {
    width: 43rem;
}

.toast-saved {
    width: 30rem;

    .slds-notify_toast, .slds-notify--toast {
        min-width:8rem;
    }
}

.toast-mapped {
    width: 30rem;
    top: 3.15rem;
    right: 11rem;

    .slds-notify_toast, .slds-notify--toast {
        min-width:8rem;
    }
}

.selection-modes-bar {
    padding-left: 21px;
    padding-bottom: 5px;
    width: 98.3%;
    margin: 0 auto 5px;
    border-radius: 0.3rem;

    ul {
        border-bottom: none;
    }

    .slds-tabs_default__link[tabindex="0"]:focus {
        text-decoration: none;
    }
}
