#update-selection-modal-dialog {
    .update-selection-modal {
        min-width: 70rem;
    }

    .selection-has-been-update-title {
        line-height: 2;
        padding-top: 0.5rem;
        text-align: center;
        color: var(--black);

        strong {
            font-weight: 600;
        }
    }

    .update-selection-table {
        margin: 1rem;
        width: 96%;

        th {
            text-align: left;
            padding: 0.25rem 0.75rem;
        }

        th:first-child {
            padding-left: 0.75rem;
        }

        td {
            white-space: inherit;
            vertical-align: middle;
            line-height: 1.5;
            padding: 0.25rem 0.75rem;
        }

        .row-update-data {
            strong {
                font-weight: 600;
            }

            .cell {
                &.removed {
                    color: var(--errorRed);
                    font-weight: 600;
                }

                &.updated {
                    color: var(--lightOrangeBorder);
                    font-weight: 600;
                }
            }
        }
    }
}