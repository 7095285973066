.preview {
  background-color: var(--white);
  margin: 0 auto 0 auto;
  width: 98.3%;
  border-radius: .3rem;
  position: relative;
  height: calc(100vh - 80.5px);
  box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: var(--lightGrayScrollTrack);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid var(--lightGrayScrollTrack);
    background-color: var(--darkenLightGrayScrollThumb);
    border-radius: 0.3rem;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &_wrapper {
    padding: 2rem 1.1rem 0 1.1rem;

    button {
      z-index: 16;

      &.disable-preview-btn {
        opacity: .7;
      }

      &.disabled-preview-btn {
        opacity: .7;

        &:hover {
          background-color: #ffc107;
          border: none;
        }
      }
    }

    #cancel-button{
      margin-left: 1.1rem;
    }

    .preview-status-container {
      min-height: 1rem;

      .preview-status {
        color: var(--darkGrayHeader);
        font-size: 0.9em;
        font-style: italic;
        margin: 15px 10px 15px 0px;
      }
    }

    .slds-button {
      .preview-loader-container {
        display: flex;
        position: relative;

        .slds-spinner {
          position: absolute;
          left: 0;
        }
      }
    }
  }

  #progress-indicator {
    position: relative;
    max-width: 40%;
    margin-top: 3rem;
    transition: 1s all;
  }

  &_result {
    overflow: auto;
    margin-top: 1.5rem;
    // height: calc(100vh - 20rem);

    &_title {
      padding-left: 1.1rem;
      padding-top: 4.5rem;
    }

    &_table {
      table {
        text-align: center;
        margin: 0;
        width: 100%;
        border-top-width: thin !important;

        .slds-notify {
          width: 80%;
          margin: 0 auto;
        }
        thead {
          tr th  {
            text-align: left;
            padding-left: 1.1rem;
            padding-right: 0;
          }

          &:last-child{
            padding-right: 1.15rem;
          }

          tr th:nth-child(1)  {
            padding-left: 1.1rem;
          }
        }
        tbody {
          tr {
            td {
              padding-left: 1.1rem;
              line-height: 1.1rem;

              .slds-notify:hover {
                color: var(--black);
              }

              div {
                line-height: 1.2rem;
              }

              div:empty::after{
                content: "\00a0";
              }
            }

            td:last-child.move-number-right {
              div {
                margin-right: 1.15rem;
              }
            }

            td:nth-child(1) {
              padding-left: 1.1rem;
            }
          }
        }
      }
    }
  }

  .penguin-class {
    width: 100%;
    text-align: center;
    transition: 1s all;

    img {
      width: 15rem;
      transform: translateY(-3rem);
      transition: 1s all;
    }

    .sad-penguin {
      width: 10rem;
    }
  }

  .tooltip-one, .tooltip-two, .tooltip-three, .tooltip-four, .tooltip-five {
    padding-left: 2rem;
    padding-top: 5rem;
    position: absolute;
  }

  #btn-tooltip-one {
    position: absolute;
    top: -3.5rem;
    left: -.8rem;
    white-space: nowrap;
  }

  #btn-tooltip-two, #btn-tooltip-three, #btn-tooltip-four, #btn-tooltip-five {
    position: absolute;
    color: var(--white);
  }

  #btn-tooltip-five {
    background-color: var(--greenActive);
  }

  .button-relative {
    position: relative;
  }

  .preview-tip-container {
    position: relative;
    max-width: 40%;
    margin: 0 auto;
    margin-top: 3rem;

    .message {
      position: relative;
      min-height: 1em;
      margin: 1em 0;
      background: #f8f8f9;
      padding: 1em 1.5em;
      line-height: 1.4285em;
      box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
      border-radius: 0.3rem;

      .header {
        color: var(--primaryOrange);
        font-size: 1.15em;
      }

      p {
        margin-top: 0.25em;
      }
    }

    .fade-in {
      transition: opacity 1s ease;
    }

    .fade-out {
      opacity: 0;
      transition: opacity 1s ease;
    }
  }
  .view-temporary-data-extension{
    margin-top: 10px;
    display: inline-block;
  }
}

.preview-data-action-info {
  margin-top: 1rem;
}

.move-number-right {
  text-align: right !important;
  padding-right: 0 !important;
}

#sql-query-modal {
  font-family: monospace;
}

.slds-modal__container {
  width: 35rem;
}

.sql-hl-keyword {
  color: var(--sqlBlue);
}
.sql-hl-function {
  color: var(--primaryRed);
}
.sql-hl-number {
  color: var(--primaryGreen);
}
.sql-hl-string {
  color: var(--primaryRed);
}
.sql-hl-special {
  color: var(--black);
}
.sql-hl-bracket {
  color: var(--black);
}
.slds-modal .slds-modal__container.sql-query-modal {
  @media (min-width: 55rem) {
    min-width: 50rem;
  }
}
.abbreviate-sql-query {
  word-wrap: break-word;
  line-height: 1.25rem;
  padding: 0.3rem;
}

#sql-query-modal span{
  white-space: pre-wrap !important;
 }

.align-items {
  display: flex;
}

table#preview-table2 {
  margin-top: 2.5rem;
}
