@import "~video-react/styles/scss/video-react";

#features-modal {

  .slds-modal__header {
    background-color: var(--lightGrayishOrange);
    border-bottom: 1px solid var(--lightGray);
    padding: 0.3rem;
  }

  #features-modal-header {
    color: var(--primaryBlack);
    font-weight: 600;
    font-size: 16px;
  }

  .feature-premium-icon {
    display: inline;
    height: 40px;
    width: auto;
  }

  .feature-list {
    margin-top: 1rem;
    margin-left: 10px;
    text-align: left;

    .feature-description {
      span{
        font-size: 14px !important;
        line-height: initial;
      }
    }
  }


  .feature-footer {
    margin-top: 1rem;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background: #add8e66a;
    width: 110%;
    margin-left: -20px;
    margin-bottom: -15px;
    padding: 1.2rem 0;

    .link {
      color: var(--darkOrangeWarning);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#features-modal.slds-modal{
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 0s ease 500ms;
}

.feature-modal-parent{
  .slds-backdrop_open{
    z-index: 9009;
  }
}

#features-modal.slds-fade-in-open{
  opacity: 1;
  visibility: visible;
  transition: opacity 500ms ease, visibility 0s ease 0s;
  z-index: 9999;
}
