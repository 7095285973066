.overview_folders_container {
  display: flex;
  width: 100%;
  padding-left: 1.4rem;
  white-space: nowrap;

  li {
    span {
      display: flex;
      align-items: center;
      margin: 0.1rem 0;
      border: 1px solid transparent;
      border-radius: 0.3rem;
      padding: 0.05rem;
      transform: translateX(-0.3rem);
      position: relative;

      &.border_folder {
        border: 1px solid var(--darkGray);
        background-color: var(--lightGrayBorder);
      }

      &.active {
        background-color: var(--lightSoftBlue);
      }

      .openFolder_icon {
        cursor: pointer;
        position: absolute;
        left: -1.1rem;
        height: 0.8rem;
        filter: invert(100%);
      }

      i {
        width: 1.3rem;
        padding-left: 0.3rem;
      }

      p.folder_element {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        height: 1.5rem;
        padding: 0 0.23rem;
        margin: 0 0 0 0.5rem;
        box-sizing: border-box;
        width: 100%;
        white-space: nowrap;
      }
    }
  }
}

.open_children {
  display: none;
}
