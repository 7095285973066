.target-definition {
  background-color: var(--white);
  margin: 0rem auto;
  width: 98.3%;
  height: calc(100vh - 80.5px);
  border-radius: 0.3rem;
  box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
  position: relative;
  padding-bottom: 2rem;
  overflow-y: auto;

  .sticky-content {
    position: sticky;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--lightGrayScrollTrack);
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid var(--lightGrayScrollTrack);
    background-color: var(--darkenLightGrayScrollThumb);
    border-radius: 0.3rem;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  .slds-tabs_scoped {
    .slds-tabs_scoped__nav {
      border-radius: 0;
      border-left: none;
      border-right: none;
      overflow-x: auto;
      overflow-y: hidden;
      .slds-tabs_scoped__item {
        max-width: 19.4rem;

        &.disable-tab {
          opacity: 0.5;
          background: var(--lightGreyedOut);
          pointer-events: none;
        }

        a {
          border-right: 1px solid var(--lightGrayBorder);
          p {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .slds-is-active {
        opacity: 1 !important;
        a {
          button {
            background-color: var(--white);
          }
        }
      }
    }

    // single
    .AF-wrapper-single {
      margin: 0 1rem 0 0rem;
      padding: 0.4rem 1.1rem;
      border-radius: 0.3rem;
      display: flex;
      justify-content: space-between;

      .TD-wrapper-single {
        grid-column: 6/25;
        width: 98.6%;
      }
    }

    .slds-tabs_scoped__content {
      display: none;
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: none;

      &.active {
        display: block;

        .AF-wrapper {
          display: grid;
          grid-template-columns: repeat(24, 1fr);

          .TD-wrapper {
            grid-column: 6/25;
            width: 98.6%;
            // transform: translateX(1rem); // causing an error
          }
        }
      }
    }

    .guidance-parent-target-tabs{
      .guidance-tip-container{
        position: absolute;
        top: 2.5em;
        left: 6.6em;
      }
    }
  }
}
