.scope-des-modal__select_container {
  .select-scope-title{
    margin-top: 1rem;
    text-align: center;
    color: var(--black);
  }
  .select-scope-description{
    color: var(--grayText);
    text-align: center;
    margin-top: 0.5rem;
    font-style: italic;
  }
  .select-scope-data-button {
    margin: 2rem auto;
    display: block;
    span {
      margin-right: 10px;
    }
    .slds-spinner{
      top: 0.1rem;
    }
  }
}
