.disable-data-action-radio-container {
  opacity: 0.4;
  cursor: default;
}

#data-action-modal-dialog {
  .slds-modal__container {
    width: 45%;

    #action-content {
      height: 16rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .DA_row {
        margin-bottom: 1.5rem;

        .DA_line {
          display: flex;
          align-items: center;
          font-weight: 600;
          line-height: 1.2rem;
          margin-bottom: 0.5rem;

          .overwrite-text, .append-text, .update-text {
            vertical-align: middle;

            .radio-label {
              margin-left: 0.3rem;
              font-size: 1.1rem;
            }
          }
        }
        .data-action-description {
          margin-left: 1.3rem;
        }
      }
    }
  }
}