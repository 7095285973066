.autorefresh-settings-container {
    position: absolute;
}
.auto-refresh-settings {
    margin-right: -131px;
    margin-top: 370px;
    padding-left: 20px;
    padding-right: 20px;
}

.input-time-picker {
    width: 100px;
    float: right;
    margin-top: 2px;
    display: flex;
    margin-right: 41px;
}

.UTC-styling {
    margin-left: 6px;
    margin-top: 0.4rem;
    font-size: 13px;
    margin-right: -47px;
    color: var(--black);
    display: flex;
}

.time-picker {
    min-width: 0!important;
}

.hours-icon {
    top: 1px;
    margin-top: 0.48rem;
    margin-bottom: 0.48rem;
    padding-bottom: 15px;
}

#days-of-week {
    color: var(--black);
    margin-top: 2px;
    font-size: 0.8rem;
    margin-bottom: 7px;
    padding-left: 10px;
}

.days-of-week-checkbox {
    float: right;
}

.footer {
    padding-top: 4px;
    padding-bottom: 2px;
    margin-top: 0.5rem;

    & button {
        margin-right: 8px;
    }
}

.auto-refresh-label {
    display: inline-block;
    box-shadow: 0 6px 6px -4px var(--blackBoxShadow);
    margin-bottom: 15px;
    padding-left: 8px;
    padding-top: 4px;
    padding-right: 8px;
    margin-left: -20px;
    width: 19.85rem;
    margin-top: -4px;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.on-off-checkbox {
    float: right;
    margin-bottom: 5px;
}

#assign-user-dropdown {
    margin-top: 4px;
    min-height: 30px !important;
    height: 0.1rem !important;
    padding: .5rem !important;
    border-radius: .25rem;
    font-size: 14px;

    i {
        padding: .5rem !important;
      }
}

.menu {
    max-height: 5.68571429rem;
}

#autoRefreshButton {
    height: 29.4px;
}

#action-save, #action-cancel {
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}

.selected-user > .default.text {
    color: var(--black) !important;
}

.assign-user-label {
    display: flex;
}