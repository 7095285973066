.timezone-modal {
  width: 40rem !important;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  height: fit-content;
  top: 50%;
  padding: 0px;

  .timezone-modal-content {
    padding: 20px;
  }

  #convertToTimezoneLabel,   #convertFromTimezoneLabel {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 700;
    color: rgb(62, 62, 62);
  }

  .timezone-dropdown {
    width: calc(100% - 1rem - 10px);
  }

}
