#modal {
    position: absolute;
}

.separators-settings {
    width: 20rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: -63px;
    margin-top: 99px;
}

.svg-icon {
    width: 19px;
}