.waterfall_view {
    width: 98.2%;
    margin: 0 auto;
    background: var(--white);
    border-radius: .3rem;
    height: calc(100vh - 80.5px);
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    display: flex;
    overflow:auto;

    .waterfall_wrapper {
        display: flex;
        width:100%;
        padding: 0.4rem 1.1rem;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--lightGrayScrollTrack);
        border-radius: 0.3rem;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid var(--lightGrayScrollTrack);
      background-color: var(--darkenLightGrayScrollThumb);
      border-radius: 0.3rem;
      background-clip: padding-box;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
};
