.selection-folders-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0.7rem;

  &.in-waterfall {
    padding-right: 0.3rem;
    margin: 0;

    .folder-settings-wrapper>p {
      color: #333;
      padding: 0;
    }
  }

  .overview_folders_wrapper {
    border: 1px solid var(--lightGrayBorder);
    width: 20rem;
    margin-bottom: 0;
    border-radius: 0.3rem;
    transition: 0.5s all;
    height: 100%;

    .folder-loading-spinner {
      position: relative;
      top: 50%;
    }

    &.hide {
      border: none;
      width: 0rem;
      overflow-x: hidden;
      pointer-events: none;
    }

    &.blur {
      opacity: 0.3;
      background-color: rgba(185, 185, 185, 0.397);
    }

    .noIdFolderArchive {
      display: flex;
      padding-left: 0.3rem;
      align-items: center;
      line-height: 1.1rem;
      height: 2rem;
      cursor: pointer;
      justify-content: flex-start;
      border: 1px solid transparent;
      border-radius: 0.3rem;
      margin: 0 0.3rem;

      span {
        margin-right: 0.5rem;
      }

        span {
          margin-right: 0.5rem;
        }

        p {
          font-weight: 400;
          white-space: nowrap;
          transition: 0.3s all;

          &.blur {
            opacity: 0.3;
          }
        }
      }

    .surrounding_folders {
      &.full-height {
        height: 100%;
      }
      &.disabled-folders{
        padding-bottom: 2em;

        .tooltip-container{
          position: absolute;
          z-index: 99;
          top: 7.4px;
          right: 9.2em;

          .slds-popover{
            position: relative;
            left: auto !important;
          }

          .slds-button_icon{
            position: absolute;
            z-index: 999;
            right: 0em;
          }
        }

        .overlay-wrap {
          background: var(--disabledOverlayOrange);
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .upgrade-badge-icon {
        top: 3px;
        left: 9em;
        bottom: 0;
        z-index: 9;
      }
      position: relative;
      padding: 0 0 0.1rem 0;
      overflow: hidden;

    .mainFolder {
      margin: 0;
      border-radius: 0;
      .openFolder_icon{
        display: inline-block;
        filter: invert(100%);
        height: 1rem;
        margin-right: 0.2em;
      }
    }
      .staticFolder {
        display: flex;
        align-items: center;
        width: 16rem;
        margin: 0.1rem 0 0 1.4rem;
        border: 1px solid transparent;
        border-radius: 0.3rem;
        padding: 0.05rem;
        transform: translateX(-0.3rem);
        position: relative;
        &.disabledFolder{
          pointer-events: none;
          opacity: 0.3;
        }

        &.border_folder {
          border: 1px solid var(--darkGray);
          background-color: var(--lightGrayBorder);
        }

        &.active {
          background-color: var(--lightSoftBlue);
        }


        i {
          width: 1.3rem;
          padding-left: 0.3rem;
        }

        p.folder_element {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          height: 1.5rem;
          padding: 0 0.23rem;
          margin: 0 0 0 0.5rem;
          box-sizing: border-box;
          width: 100%;
          white-space: nowrap;

          &.blur {
            opacity: 0.3;
          }
        }
      }

      .mainFolder,
      .noIdFolder {
        display: flex;
        padding-left: 0.3rem;
        align-items: center;
        line-height: 1.1rem;
        height: 2rem;
        cursor: pointer;
        justify-content: flex-start;
        border: 1px solid transparent;
        border-radius: 0.3rem;
        margin: 0 0.3rem;

        span {
          margin-right: 0.5rem;
        }

        p {
          font-weight: 400;
          white-space: nowrap;
          transition: 0.3s all;

          &.blur {
            opacity: 0.3;
          }
        }
      }

      .mainFolder {
        margin: 0;
        border-radius: 0;
        padding: 0;
      }

      .all-selections-folder {
        display: flex;
        padding-left: 0.1rem;
        width: 100%;

        .mainFolderText {
          width: 100%;
        }
      }

      .noIdFolder {
        width: 97%;
      }

      .created_folders {
        display: none;
        overflow: hidden;
        overflow: auto;
        margin: 0rem 0.05rem 0 0.05rem;
        padding-left: 1rem;
        width: 20rem;

        &.disabled-folders{
          .overview_folders_container{
            display: none;
          }
        }

        &.open {
          display: flex;
          flex-direction: column;
        }

        .noIdFolder {
          padding-left: 1.2rem;

          span {
            width: 1.2rem;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }

        .noIdFolder {
          padding-left: 1.2rem;

          span {
            width: 1.2rem;
            height: 1.5rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .mark_all {
      border: 1px solid var(--lightOrangeBorder);
      background-color: var(--lightOrange);
    }
  }

  &.hide-folders>.folder-settings-wrapper {
    opacity: 0;
    width: 0.3rem;
    transform: translateX(-100%);
  }

  .folder-settings-wrapper {
    width: 20rem;
    opacity: 1;
    transform: translateX(0%);
    transition: 0.5s ease;
  }

  .overview_folders_wrapper-hide {
    position: absolute;
    top: 50%;
    transform: translate(4rem, -50%);
    left: 16.7rem;
    width: 0.3rem;
    border-radius: 0.3rem;
    height: 8rem;
    background-color: var(--lightGrayBorder);
    transition: 0.5s all;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 33%;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-width: 3px 5px 3px 0;
      border-color: transparent var(--darkGrayHeader) transparent transparent;
      position: absolute;
    }

    &:hover {
      background-color: var(--gray);
    }

    &:hover>.penguin_for_hide_show_folders {
      opacity: 1;
    }

    &.hide {
      left: -2.87rem;

      &::before {
        transform: translate(-50%, -50%) rotateY(180deg);
      }
    }

    .penguin_for_hide_show_folders {
      min-width: 2rem;
      height: 2rem;
      transition: 0.3s all;
      pointer-events: none;
      opacity: 0;
      z-index: 999999999 !important;
      position: absolute;
      left: -2rem;
      bottom: 3rem;
      transform: rotate(90deg);

      &.rotate_penguin {
        left: 0.5rem;
        transform: rotate(-90deg);
      }
    }
  }
  .feature-folder-upgrade{
    padding: 0.5rem;
    font-size: 13px;
    line-height: initial;
  }
}

.border_folder {
  border: 1px solid var(--darkGray);
  margin-left: -1.2px;
  background-color: var(--lightGrayBorder);
  border-radius: 0.3rem;
}
