
.DE-Folders-container {
  height: 100%;
  display: flex;
  flex-flow: column;

  .DE-folders-main {
    background-color: var(--white);
    height: 100%;
    flex-grow: 1;
    border-radius: .3rem .3em 0 0;
    padding: 3.5rem 2rem;
    line-height: 1.2;
    overflow: scroll;
    position: relative;

    .info-text {
      font-size: 15px;
      padding-left: 10px;
    }

    .radio-buttons-container {
      padding-left: 1.5rem;
      line-height: 1.2;
      margin-top: 30px;

      .include-folders-container {
        display: flex;
        align-items: flex-end;
        position: relative;

        .recommended-text {
          font-style: italic;
          color: var(--darkGray);
        }
      }

      .slds-form-element__label > span {
        font-size: 15px !important;
      }
    }

    .folders-container {
      margin-top: 30px;
      padding-left: 1.5rem;
      display: flex;
      flex-direction: column;

      .allFolders {
        display: flex;
        margin-bottom: 0.5rem;
        background-color: var(--lightSoftBlue);
        width: 35%;
        padding: 0.1rem;
        cursor: pointer;

        .openFolder_icon{
          display: inline-block;
          filter: invert(100%);
          height: 1rem;
          margin-right: 0.2em;
          margin-top: 0.3em;
        }
        .all-folders-text {
          display: flex;
          padding: 0.1rem;

          span{
            font-size: 1.4rem;
            margin-right: 0.5rem;
            color: var(--white);
          }

          p{
            margin-top: 0.1em;
          }
        }
      }

      .loading-text-container {
        display: flex;
        align-items: center;
        width: 100%;
        flex-grow: 1;
        height: fit-content;

        .loading-text {
          font-style: italic;
          margin-left: 3px;
          font-size: 1.1rem;;
        }

        .slds-spinner {
          position: static !important;
        }
      }
    }
}

  .de-folders-footer {
    position: sticky;
    bottom: 0;
    margin-bottom: 10px;

    // toast
    .toast-position {
      position: absolute !important;
      right: -4.0rem;
      top: 1.7rem;

      .slds-theme_success {
        display: flex;
        padding: .5rem;
        align-items: center;
      }
    }

    .toast-saved {
      position: absolute;
      z-index: 1;
      z-index: 999;
      opacity: 1;
      transform: translate(-50%, -50%) rotateX(0deg);
      transition: .3s transfrom, .5s ;
    }

    .toast-none {
        opacity: 0;
        transform: translate(-50%, -101%) rotateX(180deg);
        z-index: -999;
    }
  }
}
