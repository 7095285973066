.filter-container {
  border: 1px solid var(--grayBorder);
  border-radius: .25rem;
  box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;

  .filters {
    min-height: 4rem;
    width: 100%;
    max-width: 100%;
    min-width: 88%;

    .filter-logic {
      background: var(--lightGrayIconBackground);
      display: flex;
      align-items: center;
      min-width: max-content;

      &:hover {
        background-color: var(--lightGrayIconBackgroundHover);
      }

      &:hover div .filter-operator {
        background-color: var(--lightGrayIconBackgroundHover);
      }

      div {
        overflow: hidden;

        .filter-operator {
          border: none;
          background:var(--lightGrayIconBackground);
          cursor: pointer;
        }
      }
      .slds-select_container {
        width: 100%;
      }
    }
  }

  // Where to drop information line
  .filter-drop {
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-align: center;
    padding: 1rem;
    color: var(--grayBorder);
    text-transform: uppercase;
    border: .1rem dashed transparent;
    transition: .3s all !important;
  }
}

// Transparent border for onDragEnter
.filter-container-dropzone, .filterline-dropzone {
  border: 2px dashed transparent;
  // transition: .3s all !important;
}

// Color border for onDragEnter
.bordernow {
  border: 2px dashed var(--primaryRed);
}