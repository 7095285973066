.selection_input {
  .convertText {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .sql {
    margin-bottom: 30px;
  }
  .sql-to-selection-textArea {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    resize: vertical;
  }
  .invalid-syntax-text {
    margin-top: 10px;
    color: red;
  }
  .valid-syntax-text {
    margin-top: 10px;
    color: green;
  }
  .openFolder_icon {
    display: inline-block;
    filter: invert(100%);
    height: 1rem;
    margin-right: 0.2em;
    margin-top: 0.9em;
  }
  .sql-to-selection-container {
    display: flex;
  }
}
