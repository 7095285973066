/* Animation definitions */
@keyframes shimmer {
  0% {
    border-color: var(--primaryOrange);
  }
  50% {
    border-color: var(--lightGrayishOrange);
  }
  100% {
    border-color: var(--primaryOrange);
  }
}

/* Styles for the main dialog container */
#edit-filters-modal-dialog {
  #modal-parent {
    display: flex;
    gap: 20px;
    .modal-main {
      flex-grow: 1;

      .is-response-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        flex-direction: column;
        .load-text{
          margin-bottom: 2em;
          font-size: 0.9em;
          color: var(--darkGrayText);
        }
      }

      /* Styles for the text area */
      .deedee-ai-textarea {
        width: 100%;
        height: 10rem;
        border-radius: 5px;
        border: 2px solid var(--grayBorder);
        padding: 0.5em;
        transition: border 0.5s ease;
        &:focus {
          border-color: var(--primaryOrange);
        }
        &::placeholder {
          color: var(--grayText);
        }
      }

      /* Styles for text elements */
      .deedee-quote {
        color: var(--darkGrayText);
        margin-bottom: 1rem;
      }
      .how-can-I-help-p {
        margin-bottom: 1rem;
        text-align: center;
      }
      .how-can-I-help-text {
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--primaryDarkGray);
      }

      .invalid-prompt-text{
        font-size: 1rem;
        color: var(--primaryDarkGray);
      }
      .your-selection-is-ready-text {
        margin-top: 1rem;
        text-align: center;
        color: var(--primaryGreen);
      }
      .error-text-deedee-ai {
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;
      }
      .is-response-loading-text {
        text-align: center;
      }
      .text-align-center {
        text-align: center;
      }

      /* Styles for images and icons */
      .deedee-icon {
        width: 35px;
        height: auto;
        cursor: pointer;
        margin-right: 1rem;
        margin: 0 auto;
        display: block;
      }
      .error-deedee {
        text-align: center;
        margin-bottom: 1rem;
        img {
          height: 6rem;
          width: 4rem;
        }
      }

      /* Loading spinner styles */
      .is-response-loading-spinner {
        margin-left: 12rem;
        margin-bottom: 1rem;
        position: unset;
      }

      /* Styles for spacing and margins */
      .mb-1rem {
        margin-bottom: 1rem;
      }

      .deedee-response-container {
        text-align: center;
        #open-selection-button {
          margin: 0 auto;
          display: block;
          padding: 5px 20px;
        }

        .selection-description {
          color: var(--grayText);
          margin-bottom: 2rem;
        }

        .selection-name {
          color: var(--primaryDarkGray);
          font-weight: 600;
          margin-bottom: 1rem;
          margin-top: 2rem;
        }
      }
    }
  }

  /* Styles for the main sections of the dialog */
  .container-class {
    &.container-class-expanded {
      width: 50rem !important;
    }
    width: 32rem !important;
    min-width: unset !important;
  }
  .deedee-ai-content {
    min-height: 20rem;
  }
  .deedee-ai-footer {
    height: 2rem;
  }

  /* Button styles */
  .new-request-btn {
    display: block;
    margin: 0 auto;
    padding: 5px 20px;
  }
  .deedee-ai-send-btn {
    svg {
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }
}
