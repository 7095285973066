.data-extensions-for-folder {
  padding: .5rem;
  border: 1px solid var(--lightGrayColdBorder);
  background-color: transparent;
  cursor: pointer;
  margin: .2rem;
  border-radius: .25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: -12px;

  &:hover {
    background-color: var(--lightGrayishBlueHover) !important;
    color: var(--darkDesaturatedBlue);
  }
}

.selectedDE {
  background: var(--softBlue);
}

.search-data-extension-div {
  display: flex;
  padding-bottom: 24px;
  padding-top: 24px;
  background: var(--white);
  border-bottom: 2px solid var(--lightGrayBorder);
}

.search-data-extension-label {
  padding-top: 7px;
}

.search-data-extension {
  width: 100%;
  min-width: 0;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  border: none;
  border-top-left-radius: 0.25rem;
}

.pt-1rem {
  padding-top: 1rem;
}

.folder-path-name {
  font-size: 12px;
  display: inherit;
  margin-right: 3px;
  white-space: nowrap;
}

.folder-path-div {
  display: flex;
  margin-bottom: 3px;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  text-align: left;
  height: 35px;
}

.h-12px {
  height: 12px;
}

.folder-no-data-extensions {
  font-size: 1.2rem;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-left {
  float: left;
}

.input-field-and-search-icon {
  display: flex;
  border: 1px solid var(--grayBorder);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-width: thin;
  overflow: hidden;
  margin-left: 1.1rem;
  width: 94%;
}

.search-available-folders-icon {
  padding: 0.7rem 0.8rem;
  display: flex;
  background-color: var(--lightGrayIconBackground);
  border: none;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#folder-data-extensions-modal-content {
  height: 100%;
}

.mr-2px {
  margin-right: 2px;
}
