

.guidance-tip-container {
  max-width: 2.5rem;
  position: relative;

  .tip-light-bulb {
    background-color: #FF9E2C;
  }

  .slds-popover__body {
    text-align: left;
    text-justify: none;
  }

  .guidance-tip-title {
    margin-bottom: 0.3em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .tip-icon-container {
    margin: 0 5px;
    cursor: pointer;
  }

  .slds-nubbin_top-left {
    position:absolute;
    width: 15rem;
    top: 2.8rem;
    left: -0.2rem;
    line-height: normal !important;
  }

  .slds-nubbin_top-right {
    position: absolute;
    width: 10rem;
    top: 2.9rem;
    left: -7.1rem;
    line-height: normal !important;
  }

  .disabled-feature-link {
    color: var(--lightOrangeBorder) !important;
  }

  .relation-filter-tooltip {
    top: -5.9rem;
  }

  .relation-filter-tooltip-exactly {
    top: -7.3rem;
  }

  .disabled-autorefresh {
    top: -4.3rem;
    right: -1rem;
  }

  .wp-break-spaces {
    white-space: break-spaces;
  }

  .slds-icon_container_circle{
    border: 1px solid var(--lightGrayBorder);
  }
}

#tooltip-content .slds-popover__body a { color: var(--primaryYellow);}
