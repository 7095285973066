.swal2-waterfall-selection-title {
    font-size: 1.10rem !important;
}

.selection-icon-wrapper{
    position: relative;
}

#Open-Selection-btn{
    position: absolute;
    visibility: hidden;
    opacity:0;
    bottom: 0;
    background: #fff;
    color: #136cbb;
    font-size: 8.5px;
    margin: 4px;
    padding: 4px;
    transition: all 0.1s linear;
    cursor: pointer;
    border: 1px solid #dddbda;
    border-radius: 3px;
    width: 95%;
    text-align: center;
}

.selection-container #WF-selection-icon:hover #Open-Selection-btn{
    visibility: visible;
    opacity:1;
}

.disabled {
    top: unset !important;
}