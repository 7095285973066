.free-formula-modal {
  width:100%;
  padding-bottom: 40px;

  &__title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__description {
    font-size: 15px;
    font-weight: normal;
    color: var(--grayText);
    margin-bottom: 1rem;
  }

  textarea {
    resize: none;
  }

  #valid-syntax-text {
    color: var(--greenText);
    margin-top: 5px;
    min-height: 2rem;
  }

  #invalid-syntax-text {
    margin-top: 5px;
    color: var(--primaryRed);
    min-height: 2rem;
  }

  #syntax-empty-field {
    margin-top: 5px;
    min-height: 2rem;
  }

  .custom-values-question {
    color: var(--primaryDarkGray);
  }

  .floated-left {
    width: 55%;

    .field-type {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
    }

    .formula-container {
      white-space: pre-wrap;
      width: 100% !important;
      padding: 10px 20px;
      height: 22rem !important;
      border: 2px solid var(--lightGrayScrollTrack);
      background-color: var(--white);
      border-radius: 5px;
      display: block;
      font-weight: 400;
      color: var(--primaryBlack);
      overflow-y: auto;
      line-height: 1.3rem;
    }
  }

  .preview-loader-container {
    display: flex;
    position: relative;

    .slds-spinner {
        position: absolute;
        left: 0;
    }

    .when-pressed {
      padding-left: 1rem;
    }
  }
}

.free-formula-modal-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-label {
  flex-basis:18% !important;
}

.insert-components {
  width:43%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.check-syntax-btn {
  margin-top: 10px;
  float: right;
}
