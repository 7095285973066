#relation-modal-dialog {
  max-width: none;
  width: 95%;
  margin: 0 auto;
  top: 0.7rem;

  .slds-modal__content {
    padding: 3rem 1rem 1.5rem 1rem !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .align-items {
      align-items: baseline !important;
    }

    .predefined-relation-text {
      color: var(--primaryGreen);
      align-self: center;
      font-style: italic;
    }

    .relation-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      &_one,
      &_last {
        line-height: 1.2;
        text-align: center;
        justify-content: flex-end;
        flex-direction: column;
        flex: 0 0 27%;
        max-width: 27%;

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        #relation-modal-from-collection-field,
        .relation-modal-from-collection-field {
          display: flex;
          width: 100%;
          .slds-form-element:nth-child(1) {
            padding-left: 30px;
          }
        }

        .slds-button_icon {
          width:30px;
        }

        .slds-form-element {
          width:100%;
        }

        .slds-form-element__control {
          display: flex;
          align-items: center;
        }

        #incompatible-fields-error {
          text-align: justify;
        }

        .warning-container {
          text-align: justify;
          padding-right: 3rem;

          .warning {
            font-size: 90%;
            font-weight: 900;
            color:var(--darkOrangeWarning);
          }
        }

        .slds-button__icon {
          margin-left: 10px;
        }

        .slds-select_container {
          flex-grow: 1;
        }

        .tooltip-container {
          position: relative;

          .slds-nubbin_bottom-right {
            position:absolute;
            top: -4.9rem;
            right: -0.9rem;
            width: 16rem;
            text-align: justify;
          }
        }

      }

      &_center {
        text-align: center;
        flex: 0 0 45%;
        margin: 0rem 0.2rem;

        #relation-modal-relation-type-img {
          height: 100px;
          margin-bottom: 1rem;
        }

        .margin-bottom {
          margin-bottom: 3rem !important;
        }

        .slds-form-element-bottom {
          bottom: 2rem !important;
        }

        .slds-form-element {
          .slds-form-element__control {
            .slds-select_container {
              select {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                option {
                  span {
                    width: 5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #relation-modal-button-add-relationship {
    width:fit-content;
    margin-right: 30px;
    color: var(--primaryBlack)222;
    margin-top:20px;

    #plus-icon {
      margin-right: 10px;
      color: var(--lightGrayBorder);
     }
  }

  .cross-join-container{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    &__text{
      width: 45em;
    }

  }
}

.relation-wrapper-component {
  display:flex;
  width: 100%;
  justify-content: space-between;

  .delete {
    height: 30px;

    .trash {
      color:var(--primaryRed);
      margin-right: 5px;
      margin-left: 0px !important;
    }
  }

  .error {
    text-align: justify;
  }

  .relation-wrapper-center p {
    margin-top:7.5px;
  }


}

#missing-to-fields-error, #missing-from-fields-error {
  text-align: justify;
}

.ghost-element {
  opacity: 0.0;
}
