#delete-selection-modal-dialog {

    .slds-modal__container {
        width: 28rem;
    }

    header {
        padding: 0rem;
        border-bottom:none;
    }

    #alert-header {
        padding: 1rem;

        h2 {
            font-weight: 300;
            font-size: 1.25rem;
            line-height: 1.25;
        }
    }

    .modal-container{
        width:100%;
        display: flex;
        flex-direction: column;
        padding:1rem 3rem;
        justify-content: center;
    }

    .without-dedup {
        min-height: 15.5rem;
    }

    .with-dedup {
        min-height: 18.5rem;
    }

    .without-targetDE {
        min-height: auto;
    }

    .without-options {
         min-height: 9.5rem;
         display: flex;
         text-align: center;
    }

    .question-title {
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        line-height: 1.25;
        overflow-wrap: break-word;
    }

    .not-bold-warning-text {
        font-weight: normal;
        margin-bottom: 1rem;
    }

    .selection-delete-title-wrapper {
        margin-top: 1rem;
        display: flex;
    }

    .target-DE {
        line-height: 1.5;
        margin-bottom: 0.5rem;
    }

    .checkbox-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
        align-items: center;
    }

    .preview-loader-container {
        padding-top: 1.5rem;
        margin-left: 2.5rem;

        .slds-spinner {
            position: relative;
            left: 0;
            top: -0.65rem;
        }
    }

    .checkbox {
        width: 1rem;
        height: 1rem;
    }

    .title-target-DE {
        margin-left: 1rem;
        cursor: default;

        &.active {
            color: var(--primaryBlack)
        }

        &.disabled {
            color: var(--disabledGray);
        }
    }

    .DE-name {
        margin-left: 2rem;
        font-style: italic;
        cursor: default;
    }

    .tooltip-container {
        position: absolute;
        transform: translateY(-0.1rem);
    }

    .delete-selection-tooltip {
        transform: translateY(-2.3rem);
        line-height: 1.3;
    }

    .dedup-de-name {
        margin-left: 2rem;
        cursor: default;
    }
}
