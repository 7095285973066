.timestamp-modal {
    height: 30vh;
    .custom-values-question {
        .title {
            font-weight: 600;
        }
        .description {
            font-weight: normal;
            font-size: 14px;
            margin-top: 7px;
            display: block;
            color: var(--grayText);
        }
    }

    .timestamp-format{
        display: flex;
        margin-bottom: 2rem;

        .slds-form-element__control{
            display: flex;
            .slds-radio {
                margin-top: 0.2rem !important;
            }
        }
    }

    // Radio buttons
    fieldset {
        legend {
            margin-bottom: 5px;
        }

        .slds-radio {
            margin-left: 15px;
        }
    }

    // Text + timezone dropdown
    .convert-timezone {
        display: flex;
        padding-bottom: 0.5rem;

        p {
            margin: auto 0;
            color: var(--primaryDarkGray);
            width: 11rem;
        }

        .timezone-dropdown {
            margin-left: 10px;
            flex: 1 1 0;
            width: 100%;
        }
    }
}
