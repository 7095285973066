.dynamic {
  max-height: 353px;
  min-height: 243px;
  .react-datepicker__input-container {
    min-width:100%;
  }
}

.custom-values-input {
  .fixed-value, .dynamic-value {
    display: grid !important;
    grid-template-columns: 100px auto;
    width: 100%;
    .react-datepicker-wrapper {
      max-width: 100%;
    }
  }
  .field {
    display: flex;
    margin-bottom: 10px;
    > div:first-of-type {
      margin: 6px 0;
      color: var(--primaryDarkGray);
    }
  }
}



.field-value {
  flex-grow: 1;
}

.field-value-dynamic {
  display: flex;
  margin-top: 15px;

  .react-datepicker-wrapper {
    max-width: 100%;

    .then-clause-date {
      width: 100%;
    }
  }
}

.null-checkbox {
  width: max-content;
  margin-left: 0px !important;
  margin-top: 0.5rem;
}

.then-value-dynamic {
  float: right;
  display: flex;
}
.then-select-value {
  width: 100%;
}
.boolean-filter-value {
  height: calc(1.875rem + 2px);
}
.criteria-container {
  display: grid !important;
  grid-template-columns: 100px auto;
  width: 100%
}
.item-label {
  flex-basis: 15%;
}
.dynamic-line {
  margin-bottom: 10px;
  border: .1rem solid var(--grayBorder);
  padding: 1rem;
  border-radius: .25rem;
  flex-basis: 85%;
  display: flex;
  background: #fff;

  .dynamic-line-body{
    flex:1
  }

  .when-container {
    display: flex;
    align-items: baseline;
    .flex-when {
      display: flex;
    }
  }
}
.add-criteria-button {
  float: right;
}
#header-all-null-warning {
  font-weight: 600;
  color: var(--primaryDarkGray);
}
.dynamic-value-input-container {
  margin-left: 18px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}
.delete-criteria-button {
  border-color: var(--lightGrayBorder);
  width: 2rem;
  margin-left: 10px;
  height: calc(1.875rem + 2px);
}
#move-criteria {
  margin-top: -0.3rem !important;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: .5rem;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.edit-criteria-btn {
  margin-left: 5px;
  margin-top: -6px;
  border-color: var(--lightGrayBorder);
  width: 2rem;
  height: calc(1.875rem + 2px);
}
.react-datepicker__input-container {
  height: calc(1.875rem + 2px);
  width: 50%;
  .custom-value-date {
    flex-grow: 1;
    height: calc(1.875rem + 2px);
    width:50%;
  }

}
.dynamic-values-select {
  margin-left: 18px;
  width: 100%;
}
.default-value-container {
  flex-grow: 1;
}
.dynamic-filter-text {
  margin-left: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2rem;
  word-break: break-all
}
.default-fields {
  flex-grow: 1;
}
.default-fields:first-of-type {
  margin: 0 5px;
}
.default-value-input {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  margin-left: 10px !important;

  // Dynamic screen
  .react-datepicker-wrapper {
    flex-grow: 1;
    max-width: 100%;
  }
}
.input-type-toggle-button {
  height: calc(1.875rem + 2px);
  margin-left: 10px;
}

.input-fields {
  flex-grow: 1;
}

.input-type-select {
  display: flex;
  flex-grow: 1;
  margin-left: 5px;
  height: calc(1.875rem + 2px);

  #no-available-fields-error {
    position: absolute;
    text-align: justify;
  }

  .null-checkbox-hidden {
    width: max-content;
    visibility: hidden;
  }
}

.input-fields:first-of-type {
  margin: 0 5px;
}

.default-values {
  display: flex;
  margin-top: 70px;
  >div:nth-child(1) {
    min-width:100px;
    max-width:100px;
    flex-basis: 15%;
  .default-value-inputs-container {
    display: flex;
    flex-grow: 1;
  }
}
.default-value-inputs-container {
  display: flex;
  width:100%;
}
.default-value-button {
  height: calc(1.875rem + 2px);
}
.add-criteria-btn {
  margin-right: 83px;
  margin-left: 0px;
}
.dynamic-value-date {
  flex-grow: 1;
  padding-left: 0.75rem;
}
.mr-11 {
  margin-right: 11px;
}
.ml-23 {
  margin-left: 23px;
}
.default-value-boolean {
  flex-grow: 1;
  margin-left: 10px !important;
  margin-top: 0px !important;
  height: calc(1.875rem + 2px);
}
}

#add-values-modal .slds-modal__container > .criteria-modal {
  width: 100%;
}
