#deedee-ai-feedback-modal {
  right: 4.5em;
  top: auto;
  left: auto;
  bottom: 3.5em;

  .slds-modal__container {
    .slds-modal__content {
      min-height: 280px;
      overflow-y: hidden;
    }
    width: 25rem;
    border: 1px solid var(--grayBorder);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 14px; /* adjust these values to change the size of the triangle */
      border-color: transparent transparent transparent var(--grayBorder); /* replace var(--grayBorder) with the color of your div border */
      right: -14px; /* this should be the negative of the second border-width value */
      top: 50%;
      transform: translateY(-50%);
    }

    .deedeeai_feedback__container {
      padding: 0.5em;

      .deedeeai_feedback__logs{
        margin-bottom: 1em;
        .log-item{
          color: var(--errorRed);
          display: inline-block;
          margin-right: 5px;
        }
      }
      .deedeeai_feedback__title {
        margin-bottom: 1em;
      }
      .feedback-received-text {
        text-align: center;
        margin-top: 10%;
        .thanks-message{
          display: block;
          width: 100%;
          margin-bottom: 1em;
          font-weight: 600;
          font-size: 1.2em;
        }
        .rating-icon {
          .slds-button__icon {
            height: 2.5em;
            width: 2.5em;
          }
          display: flex;
          justify-content: center;
          height: 2.5em;
          border-radius: 4px;
          color: var(--darkGrayText);
          font-size: 0.9em;
          font-weight: 600;
          transition: all 0.2s ease-in-out;
          margin-bottom: 2em;
        }
      }

      .neg-feedback-container {
        display: flex;
        flex-direction: column;
        .feedback-title {
          margin-bottom: 0.5em;
          font-weight: 600;
        }

        .feedback-processing-log{
          margin-bottom: 1em;

          .log-item{
            color: var(--primaryRed);
            display: inline-block;
            margin-right: 5px;
          }
        }

        .feedback-description {
          margin-bottom: 1em;
        }

        .feedback-textarea {
          background-color: white;
          border: 1px solid #dddbda;
          border-radius: 0.25rem;
          margin-bottom: 1em;
          height: 100px;
          padding: 0.5em;
        }

        .feedback-submit {
          display: inline-block;
          width: 60%;
          padding: 5px 20px;
          border:1px solid var(--lightGrayBorder);
          color: var(--primaryDarkGray);
          text-align: center;
          .button-children {
            display: block;
          }
          &:hover {
            background-color: var(--lightGrayHover);
          }
        }
      }

      .submit-rating-container {
        .rating-title {
          font-weight: 600;
          margin-bottom: 1em;
        }

        .rating-buttons {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
          width: 30%;
          .rating-button {
            .slds-button__icon {
              height: 1.5em;
              width: 1.5em;
            }
            display: flex;
            justify-content: center;
            width: 48%;
            height: 2.5em;
            border-radius: 4px;
            border: 1px solid var(--lightGrayBorder);
            background-color: white;
            color: var(--darkGrayText);
            font-size: 0.9em;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &.positive {
              &:hover {
                background-color: var(--lightGrayHover);
              }
            }

            &.negative {
              &:hover {
                background-color: var(--lightGrayHover);
              }
            }
          }
        }
      }
    }
  }
}
