.slds-form-element {
    .slds-checkbox_toggle {
        span {
            font-weight: 700;
        }
    }
}

.info-about-prio-dedup-status {
    font-size: .9rem;
}