.overview_header {
  background-color: transparent;
  padding: .75rem 0rem;
  display: flex;
}

.unavailable-feature{
  .slds-icon-standard-account{
    background: var(--disabledOverlayOrange) !important;
  }
  .overview-item-content{
    opacity: 0.6;
    color: var(--darkGray);
  }
}


.overview_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: .75rem auto;
  border-radius: .3rem;
  background-color: var(--white);
  padding: .70rem 1rem;
  width: 98.3%;
  box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);

  .unavailable-feature{
    background: var(--disabledOverlayOrange);
    .slds-icon-standard-account{
      background: rgba(0, 0, 0, 0.341);
    }
    .tooltip-container{
      position: absolute;
      top: -1em;
      right: -0.8em;
    }
  }

  .upgrade-badge-icon {
    top: -5px;
    right: -10px;
  }

  #selection-search {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .selection-count-limits {
    margin: 0 0.5em;
    text-align: center;
    font-weight: bold;
  }
  #calendar-view-for-schedules {
    margin-right: 0.75rem;

    .slds-icon_container{
      margin: 0;
    }
    .slds-icon-standard-account{
      background: #fff;
    }
    .slds-icon{
      fill: var(--darkGray);
      padding: 3px;
    }

    .upgrade-badge-icon{
      top: -8px;
      right: -13px;
    }

    .tooltip-container{
      margin: 0px;
      top: -1em;
      right: -0.5em;
      position: absolute;
    }

  }
  #calendar-view-for-schedules.slds-button_neutral{
    padding: 0;
  }

  .overview-title-section-wrapper {
    display: flex;
    justify-content: space-between;

    .selected {
      background-color:  var(--greenActive);
      color: var(--white);
      transition: background 0.2s linear;

      &:hover {
        background-color: var(--primaryGreen);
      }
    }
  }

  &_button {
    display: flex;
    min-width: 14rem;
    justify-content: flex-end;

    .slds-icon_container {
      margin: 5px;
    }

    .guidance-parent-new-selection{
      position: absolute;
      .guidance-tip-container{
        position: absolute;
        top: 1.8em;
        right: 0;
        z-index: 3;
      }
    }


  }

  &_title {
    color: var(--primaryBlue);
    font-size: 2rem;
    font-weight: 500;
    display: flex;
    margin-right: 2rem;
    .slds-button__icon{
      height: 2rem;
      width: 2rem;
    }
  }

  &_middleContainer {
    display: flex;
    max-width: 30rem;

    // Criteria search
    .dropdown-for-criteria {
      width: 8rem;
      flex: .5;
      transition: .2s cubic-bezier(.62,.65,.88,1.14);

      &.longerinput {
        flex: .6 !important;
      }

      .slds-form-element__control {
        .slds-select_container {
          .slds-select {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }
    }

    #updatedBy-search, #createBy-search {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    //Serch by
    .slds-form-element:nth-child(2) {
      width: 16rem;
      flex: 1;
    }

    .guidance-tip-container{
      position: absolute;
      right: -0.8em;
      top: -0.15em;
      z-index: 3;
    }
  }

}

.makeLonger {
  flex: .8 !important;
}

.navbar-css {
  margin-right: 0.9em;
}

