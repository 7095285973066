.prio-val-h2 {
  margin-top: 0.3rem;
  font-size: 0.9rem;

  span {
    font-weight: 600;
  }
}

.prio-val-container {
  margin-top: 0.4rem;
  overflow-y: auto;
  border: 0.1rem solid var(--lightGrayBorder);
  padding: 0.1rem;
  max-height: calc(100vh - 20rem);
  border-radius: 0.3rem;

  &-elements {
    background-color: var(--white);
    margin: 0.4rem 0.4rem;
    border-radius: 0.3rem;
    padding: 0.4rem;

    ol {
      span {
        line-height: 2rem;
      }

      li:nth-child(1) {
        margin-top: 0;
      }
      li:last-child {
        margin-bottom: 0;
      }
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--mostlyWhiteBackground);
        margin: 0.3rem 0;
        border: 0.05rem solid var(--lightGrayBorder);
        border-radius: 0.3rem;

        &:hover {
          background-color: var(--lightGrayBorder);
          border-color: var(--black);
        }

        p {
          margin: 0 auto 0 0.3rem;
          font-size: 1rem;
          text-overflow: ellipsis;
          overflow: hidden;

          span {
            margin-left: 0.2rem;
            margin-right: 0.4rem;
            font-weight: 600;
          }
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.3rem;

          &:hover {
            text-decoration: none;
          }

          i {
            margin-right: 0.3rem;
          }
        }
      }
    }
  }

  .remove-line {
    padding: 0;
    border: none;
    background: none;
  }
}
.drag-icon-prio {
  color: var(--black);
  margin-right: 10px !important;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}
