.display-flex {
  display: flex;
}

.ml-4px {
  margin-left: 4px;
}


.global-custom-value-dropdown {
  height: 1.875rem;
  width: calc(100% - 1.5em) !important;

  .item {
    display: flex !important;
    flex-direction: column-reverse !important;

    .description {
      margin: 0.5em 0 0 0 !important;
    }
  }
}

#global-value-modal {
  padding: 1em 2em;
  min-height: 75vh;

  .selected-data-extensions {
    margin-left: 0px;
    margin-right: 0px;
    width: 100% !important;
  }

  .row-number-wrapper{
    .dropdown-container{
      .item-label{
        flex-basis: 25% !important;
      }
      .dropdown-field{
        flex-basis: 75% !important;

        .target-data-extension-dropdown{
          width: 100% !important;
        }
      }
    }
  }
}

#global-custom-value-dropdown-formula-type, #global-custom-value-dropdown-type{
  margin-right: 0px;
  width: 100% !important;
}

.global-custom-value-dropdown.selected-data-extensions{
  height: fit-content;
  max-height: 100% !important;

  .ui.label{
    display: inline-block;
    margin-bottom: 5px;
  }
}

.free-formula-modal{
  .slds-modal__container{
    width: 55% !important;
  }
}

.selected-de-label {
  display: inline-block;
  margin: 0.5rem 0 1rem 0;
  font-size: 15px;
  font-weight: 700;
}

.name-label {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 700;
  margin-top: 2rem;

  .button-children {
    padding: 0.3rem;
    font-weight: 500;
    font-size: 0.95rem;
  }
}

strong {
  font-weight: bold !important;
}

.tooltip-container {
  display: inline-block;
}

.custom-values-input {

  .fixed-value,
  .dynamic-value {
    display: grid !important;
    grid-template-columns: 100px auto;
    width: 100%;

    .react-datepicker-wrapper {
      max-width: 100%;
    }
  }

  .field {
    display: flex;
    margin-bottom: 10px;

    >div:first-of-type {
      margin: 6px 0;
      color: var(--primaryDarkGray);
    }
  }
}

.global-custom-value-dropdown {
  .label {
    color: black;
    padding: .2833em 1.5em .2833em 0.9em;
    text-decoration: none;
    background-color: var(--lightGreyedOut);
    margin: 0 .14285714em;
    border: 0 solid transparent;
    border-radius: .28571429rem;
  }

  i.icon.delete {
    color: var(--grayBorder);
    font-size: x-large;
    position: absolute;
    padding-left: 0.2em;
  }

  i.icon.delete:before {
    content: "\00d7";
  }
}

.slds-modal__container {
  .name-label {
    margin-top: 0em;
  }

  .in-modal-dropdown {
    margin-bottom: 2em;
  }
}

.ui.multiple.search.dropdown>input.search {
  position: static;
  width: 0.1em;
  padding: 0;
}


.global-custom-values-body{
  .selected-data-extensions {
    margin-left: 26px;

    .dropdown.icon {
      top: 20% !important;
    }

    max-height: calc(1.875rem + (1px * 2));
    min-height: 0px !important;
    padding: 0.21em 0.05em 0.05em 0.05em !important;
    display: inline-flex;

    .label {
      font-size: 0.9em;
      padding: .2133em 1.5em .2133em 0.9em;
    }
  }
}


#add-values-modal.criteria-modal {
  .slds-modal__container {
    width: 100%;
  }
}

#add-values-modal {
  .name-label {
    margin-left: 26px !important;
  }
}

.name-on-loading-custom-value {
  font-weight: bold;
}

.text-on-loading-custom-value {
  text-align: center;
}
