.navigation_bar_waterfall {
  &_header {
    background-color: transparent;
    padding: .75rem 0;
  }

  &_wrapper {
    border-radius: .3rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    background-color: var(--white);
    padding: .35rem 1rem;
    box-shadow: 0px 0px 5px 1px var(--darkOrangeBoxShadow);
    align-items: center;
    width: 98.3%;

    .waterfall_title_header {
      color: var(--darkGrayHeader);
      font-size: 1.8rem;
      font-weight: 500;
      display: flex;
      grid-column: 1/8;
    }

    .selection_input{
      grid-column: 8/14;
    }

    .selection_criteriaNavigation {
      grid-column: 14/23;
    }

    .buttons_container {
      grid-column: 24;
      display: flex;
      min-width: 16rem;
      justify-content: flex-end;

      .disabled {
        fill: var(--disabledGray);
      }

      .schedule-btn-container {
        margin: 0 0.25rem;
      }

      #open-schedule-waterfall {
        width: 2rem;
      }

      #schedule-icon {
        &.schedule {
          background-color: var(--white);
          fill: var(--darkBlue);
          height: 1.875rem;

          &:hover {
            background-color: var(--lightGrayHover);
          }
        }

        &.schedule-active {
          background-color: var(--greenActive);
          height: 1.875rem;

          &:hover {
            background-color: var(--primaryGreen);;
          }
        }
      }

      #cancel-waterfall-button{
        .slds-button__icon{
          margin-right: 0.35rem;
          margin-bottom: 0.1rem;
        }

        background-color: var(--primaryBlue);
        color: var(--white);
        border-color: var(--primaryBlue);

        &:hover:not(:disabled) {
          background-color: var(--darkBlue) !important;
        }
      }
    }
  }
}
