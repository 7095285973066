.schedule-selection-modal {
    min-width: 36rem;

    .datepicker {

        &:disabled {
            background-color: var(--lightGreyedOut);
        }
    }

    .react-datepicker {
        .react-datepicker__day--today {
            background-color: var(--white) !important;
            color: var(--black);

            &:hover {
                background-color: var(--primaryLightGray) !important;
              }
        }
    }

    footer {
        display:flex;
        justify-content: flex-end;
        align-items: center;
    }
}

.timezone-content {
    display:flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    align-items: center;
}

.UTCTimeOffset-schedule-text {
    font-size: 0.9rem;
    color: var(--primaryDarkGray);
    flex-basis: 40%;
}

.schedule-selection-content {
    padding: 1.5rem 2rem;

    .checkbox-label {
        padding-top: 0.4rem;
        font-weight: bold;
    }

    .turn-on-off-schedule {
        display: flex;
        justify-content: space-between;

        #toggle-button-label {
            width: fit-content;
        }
    }
}
.schedule-options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.9rem;

    .disableCursor {
        cursor: default !important;
    }

    .header-options {
        display: flex;
        border-bottom: 1px solid var(--lightGrayBorder);
        width: 100%;
        font-size: 0.9rem;

        .schedule-option {
            width: fit-content;
            padding: 10px;
            cursor: pointer;

            &.activeOnce {
                background-color: var(--modalGrayBackground);
                color: var(--primaryBlue);
                font-weight: bold;
            }

            &.activeRepeat {
                background-color: var(--darkBrownGray);
                color: var(--white);
            }

            &.disableActiveOnce {
                color: var(--primaryBlack);
                background-color: var(--modalGrayBackground);
                font-weight: bold;
                cursor: default !important;
            }
        }

    }

    .content-for-schedule-options {
        background-color: var(--modalGrayBackground);
        min-height: 9.5rem;

        .next-run-information {
            padding-top:1rem;
            line-height: 1.5;
            font-style: italic;
            color: var(--primaryDarkGray);
        }

        .hourly-next-run-information {
            line-height: 2;

            .hourly-second-info {
                padding-top:0.6rem;
                line-height: 1.5;
                font-style: italic;
                color: var(--primaryDarkGray);
            }
        }

        .date-time-container {
            display: flex;
            align-items: center;
            padding: 10px 0;
            font-size: 0.9rem;

            p {
                margin: 0 10px;
            }

            .datepicker {
                margin-right: 0.5rem;
            }

            .react-datepicker-wrapper {
                width:auto;
            }
        }

        .react-datepicker__input-container {
            width:35%;
        }

    }

    .time-period-buttons {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--lightGrayBorder);

        span {
            margin: 0 10px 0px 10px;
            padding-bottom: 5px;
            cursor: pointer;
        }

        .active {
            color: var(--primaryBlue);
            font-weight: bold;
            border-bottom: 1.5px solid var(--primaryBlue);
        }
    }

    .disabledTitle {
        color: var(--disabledGrayText);

        span {
            cursor: default !important;
        }
    }

    .time-period-content {
        padding: 10px;
        display:flex;
        justify-content: space-between;
        flex-direction: column;
        height: 7rem;
        justify-content: space-between;
    }

    .repeat-time-content {
        display:flex;
        align-items: center;
        flex-wrap: wrap;

        input, select {
            width: 60px;
            margin: 0 0.5rem;
            padding: 0 0 0 0.5rem;

            option:first-of-type {
                display:none;
              }
        }

        .react-datepicker-wrapper {
            width: 17%;
        }

        .last-daily-span {
            padding-top:0.5rem;
        }
    }

    .weekly {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        >div:first-child {
            font-size: 0.9rem;
            font-weight: bold;
        }

        .days-of-week-schedule {
            display:flex;
            align-items: center;
            font-size: 0.9rem;

            #schedule-days-of-week {
                padding: 0;
                display: flex;
                padding-left:0;
                margin: 0;
                align-items: center;
                font-size: 0.9rem;
                cursor: default;
                &:first-child {
                    input {
                        margin-left: 0;
                    }
                }
            }

            input {
                width: fit-content;
                margin: 0 0.3rem 0 0.5rem;
                font-size: 0.9rem
            }

            span {
                margin: 0 0.5rem;
            }

            >span:last-child {
                margin:0;
            }

            .datepicker {
                width: 60px;
                margin-left: 0;
            }
        }
    }
}

.next-schedule-run-date {
    text-align: start;
    font-size: 0.9rem;
    color: var(--primaryDarkGray);
    font-weight: bold;
    margin-right: auto;
}

.clashing-schedules-error .swal2-content {
  padding: 10px !important;

  .scheduled-tde {
    padding-top: 0.5em;
  }

  ul {
    list-style-type: circle;
  }

  strong {
    font-weight: bold !important;
  }
}

.clashing-schedules-error {
    ul {
        padding-left: 2rem;
        list-style-type: square;
        text-align: start;
        }
}

.part-of-scheduled-waterfall-notice {
    line-height: 1.3em;
    word-wrap: break-word;
}
