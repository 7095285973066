#picklists-panel.contact-relations-table {
  height: 66%;
  border-radius: 0;
  padding-top: 2.5rem;
}

.import-relations-title {
  margin: 0.8rem 0;
  font-size: 1.5em;
  font-weight: bold;
}

.attribute-main {
  background-color: var(--white);
  flex-grow: 1;
  border-radius: .3rem .3em 0 0;
  padding: 1.2rem 1.5rem;
  line-height: 1.2;
  position: relative;

  .info-header {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
    padding-left: 10px;
  }

  .info-text {
    font-size: 15px;
    color: var(--grayText);
  }

  &__actions {
    margin-top: 15px;
    display: flex;

    .tooltip-container {
      display: flex;
      align-items: center;
    }
  }

  .selection {
    height: auto !important;
  }

  &__dropdown {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 10px;
  }

}

.swal-content-left {
  text-align: left !important;
}
