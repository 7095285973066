.pickLists-spinner {
    position: unset;
}
.separator {
    display: flex;
    align-items: center;

    span {
        margin-right: 10px;
    }
    .form-control-elements {
        width: 100%;

        input {
            flex-grow: 1;
        }
    }
}