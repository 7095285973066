.filters_wrapper {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(3, auto);
    // min-height: 27rem;

    .sticky_left_column {
        grid-column: 1/6;
        position: sticky;
        top: 0px;
        margin-right: 10px !important;
      }

    #filters {
        margin-bottom: 1.5rem;
        box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
    }
}