.prio-type {
    .slds-form-element__legend {
        margin-bottom: .4rem;
        width: 100%;
    }

    div {
        .slds-radio_button-group {
            display: flex;

            span {
                flex: 1;
                text-align: center;

                label {
                    width: 100%;
                }
            }
        }
    }
}

.getHelpText {
    margin-top: .3rem;
    font-size: .9rem;
}
