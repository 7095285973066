.target-de-container {
  display: flex;
  flex-flow: column;
  align-items: center;

  .relation-div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    transform: translate(-2rem);

    label {
      display: flex;
      align-items: center;

      span {
        padding: 0 .5rem;
        margin-bottom: .5rem;
        transform: translateY(.2rem);
      }
    }
  }

  .default-send-relationship-tooltip-container {
    padding-top: 0.5em;
  }

  #DE-loadingmodal .lmo {
    top: 50vh;
    left: 50vw;
  }

  .target-de-main {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    align-items: center;
    position: relative;

    h4 {
        margin-right: 2rem;
        line-height: 2.5rem;
        font-weight: 600;
    }

    .create-new-de-user-actions {
        display: flex;

        button {
          margin-left: .2rem;
          display: flex;
          align-items: center;

          i {
            font-size: 1.2rem;
            transform: translateY(-.05rem);
            margin-right: .4rem;
          }
        }
    }
  }

  .remove_duplicates {
    margin: 0 1.1rem 0 .3rem;
    right: 0;
    min-width: 11rem;
  }

  .cancel-update-button {
    border: none;
    background-color: var(--primaryBlue);
    color: var(--white);
    -webkit-transition: .3s all;
    transition: .3s all;
    border-radius: .5rem;
    padding: 3px 1rem;
    margin-left: 13px;
    width: 85px;
    display: block!important;
  }

  .settings-actions{
    .slds-dropdown_actions {
        position: absolute;
        display: none;

        &.active {
            display: block;
            top: 2rem;
        }
    }
  }
}

.select-TDE-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  width: 100%;

  .select-TDE-info {
    line-height: 2.5rem;
    padding-right: .6rem;
    white-space: nowrap;
    height: 2.2rem;
  }

  button {
    height: 1.9rem;
  }
}

.refresh-button {
  margin-left: .25rem;
  height: 2rem;
  width: 2rem;
  transition: .5s all;
  svg {
    transform: rotate(360deg);
    position: absolute;
  }

}

.create-new-data-extension-button {
  margin-left: 0.228rem !important;
  min-width: 13rem;
}

.target-data-extension-dropdown {
  width: 27rem !important;
  max-height: 3rem !important;
  line-height: .27rem !important;
  margin: 0 !important;
  min-height: 0px !important;
  height: 2rem !important;
  padding: .5rem !important;

  input {
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.9rem !important;
    padding-left: 1rem !important;
    height: 100% !important;
    width: 25rem !important;
  }

  div.text {
    width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1rem;
    height: 1.2rem;
  }

  i {
    padding: .5rem !important;
  }

  .item {
    height: 2rem !important;
    line-height: .3rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    span {
      white-space: nowrap !important;
    }
  }
  .selected.item {
    font-weight: 400 !important;
  }
}

.col-button-update {
  margin: 0 3px 0 3px;
  font-size: 16px;
}

.targetDE-loader-container {
  display: flex;
  justify-content: flex-end;
  height:2.2rem;
  align-items: center;
  position: relative;

  .slds-spinner {
    left: 95%;
    position: absolute;
    z-index: 1 !important;
  }
}

.target-de-loading-dropdown {
  .icon {
    display: none !important;
  }

  .text {
    color: var(--darkGray) !important;
  }
}

.target-folders-funnel-icon {
  border: 1px solid #dddbda;
  height: 1.9rem;
  width: 2rem;
  border-radius: 0.25rem;
  justify-content: center;
  transition: border 0.15s linear;
  color: #0070d2;
  margin-left: 0.25rem;
}
