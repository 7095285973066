.advanced-prio-filters-container {
    li {
        display: flex;
        margin-left: -20%;
        width: 140%;
        vertical-align: middle;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;

        .advanced-prio-icons {
            display: flex;
        }

        .rule-index {
            padding-right: .3rem;
            font-weight: bold;
        }

        p {
            width: 70%;
            margin-left: 1rem;
            margin-right: 0.5rem;
        }

        a {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
}

.slds-button:focus {
    box-shadow: none !important;
}

.drag-and-drop:hover {
    cursor: grab;
  }
.drag-and-drop:active {
    cursor: grabbing;
  }


#drag {
    padding-right: .6rem;
    color: var(--black);
}