.slds-modal__container {
    width: 28rem;

    .slds-modal__content {
        .empty-DE_name_error-space {
            height: 1rem;
        }

        .slds-form-element {

            &.space-for-error {
                height: 6rem;
            }

            label {
                abbr {
                    text-decoration: none;
                }
            }
        }

        .new-target-de-location {
            display: flex;
            align-items: center;
            i, div {
                margin-right: .5rem;
            }
        }

        .new-target-de-label {
            margin-top: .6rem;
        }

        .checkbox-container {
            .checkbox-sendable, .checkbox-testable {
                font-size: 1rem;
            }
        }

        .policy {
            display: flex;
            align-items: center;
            margin-top: .1rem;
            .slds-button {
                margin-left: 1.5rem;
            }
        }
    }
}

#newAutoCreatedTDE {
    .de-name-error-alert {
     margin-top: 0.3em;
    }
 }

// second modal
#second-modal {
    z-index: -10;
    visibility: hidden;

    .slds-modal__container {
        .slds-modal__content {
            min-height: 23.8rem;
            height: 25.6rem;
        }
    }

    &.show {
        z-index: 9500;
        visibility: visible;
    }
}

.copySelection-modal {
    .slds-backdrop_open {
        visibility: hidden;
    }

    .new-target-de-location-name {
        max-width: 18rem;
        overflow-wrap: break-word;
    }
}

.new-target-de-location {
    margin-top:0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
}

.folder-with-icon {
    display: flex;
    align-items: center;
}

.change-copy-target-de-location,
.change-new-target-de-location {
    line-height: 1rem;
    min-width: 7rem;
}

.name-copy-targetDE {
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;

    span {
        flex-basis: 60%;
    }

    .slds-required {
        margin: 0;
        left: -0.5rem;
        position: absolute;
    }

    .slds-form-element__control {
        width: -webkit-fill-available;
    }
}

.copy-target-de-label {
    font-weight: 600;
}

.left-8px {
    left: 8px!important;
}

.pl-2rem {
    padding-left: 2rem!important;
}

.content-spinner {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .slds-spinner_x-small {
        width: 1rem;
    }
}

.content-loading-search-folders {
    height: 100%;

    .loading-available-folders-spinner {
        left:50%;
        top: 54%;
    }
}

.loading-available-folders-spinner {
  left: 35%;
  top: 50%;
}

.loading-available-folders-label {
  font-size: 1.2rem;
  margin-left: 1.5rem;
}

#available-DEs-folders {
  #cancel-btn {
    float: left;
  }

  #available-folders-modal-content {
    height: 100%;
  }
}