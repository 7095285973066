#selection-run-logs-modal-dialog {

    .slds-modal__container {
        width: 40rem;
        max-width: 40rem;

        &.true {
            width: 45rem;
            max-width: 45rem;
        }

        .slds-modal__title {
            font-weight: 400;
        }
    }

    .slds-modal__content {
        min-height: 12rem;
    }

    header {
        padding: 1rem 0rem;
        border-bottom: none;
    }

    #selection-run-logs-header {
        .run-logs-selection-name-in-header {
            font-weight: 700;
        }
    }

    #selection-run-logs-tabs{
        display: flex;

        .tab {
            margin-right: 0.1rem;
            line-height: 1rem;
            padding: 0.5rem;
            background-color: var(--lightGrayBorder);
            border-radius: 0.5rem 0.5rem 0rem 0rem;
            border-color: var(--grayBorder);
            border-width: 1px;
            border-style: hidden;
            border-bottom: 0px;
            cursor: pointer;
        }

        .active {
            background-color: var(--lightGrayTableHeader);
            border-style: solid;
        }
    }

    .modal-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        justify-content: center;

        .no-run-logs-found {
            padding: 1rem 0.3rem;
        }
    }

    .demo-only {
        position: relative;
        top: 50%;

        .slds-spinner {
            position: relative;
            top: -0.65rem;
        }
    }

    .selections_run_log_list,
    .selections_run_logs_list_header {
        display: grid;
        grid-template-columns: 2fr 1.5fr 1.5fr 1fr 1fr;
    }

    .selections_run_log_list {
        line-height: 1.5rem;
        height: 1.86rem;
        align-items: center;

        li {
            margin-left: 0.3rem;
        }

        li:nth-child(4),
        li:nth-child(5) {
            text-align: center;
        }

        .run-logs-details {
            height: 1rem;
            width: 1rem;
            fill: var(--darkGrayText);

            &:hover {
                fill: var(--darkBlue);
                cursor: pointer;
            }

            &.disabled-log-btn,
            &.disabled-log-btn:hover {
                cursor: not-allowed;
                fill: var(--grayBorder);
            }
        }
    }

    .header-for-selected-run-log {
        align-items: center;
        display: flex;
        justify-content: center;

        .back-icon {
            position: absolute;
            left: 0.5rem;
            height: 1.25rem;
            width: 1.25rem;

            &:hover {
                cursor: pointer;
                color: var(--darkBlue);
            }
        }
    }

    .run-logs-header-scroll {
        position: sticky;
        top: 0;
        white-space: nowrap;
        color: var(--darkGrayText);
        background-color: var(--lightGrayTableHeader);

        &.true {
            display: none;
        }

        .selections_run_logs_list_header {
            line-height: 2rem;
            height: 1.86rem;
            align-items: center;
            font-weight: 700;

            li:nth-child(1) {
                padding: 0 0.3rem;
            }

            li:nth-child(2),
            li:nth-child(3) {
                border-left: 1px solid var(--lightGrayBorder);
                padding: 0 0.3rem;
                text-align: start;
            }

            li:nth-child(4),
            li:nth-child(5) {
                border-left: 1px solid var(--lightGrayBorder);
                padding: 0 0.3rem;
                text-align: center;
            }
        }
    }

    .status-error {
        fill: var(--primaryRed);
    }

    .status-success {
        fill: var(--primaryGreen);
    }
}

.selection-run-log-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid var(--lightGrayBorder);

    &.without-border {
        margin-top: 1rem;
        border: none;
    }

    .selection-run-logs-details-container {
        h2 {
            border-bottom: 1px solid var(--lightGrayBorder);
            padding: 0.3rem;
        }

        h2:nth-last-child(1) {
            border-bottom: none;
        }
    }

    .selection-run-logs-details-full-width {
        grid-column: 1 / span 2;
        border: 1px solid var(--lightGrayBorder);

        .slds-notify_alert {
            padding: 0.5rem;
        }
    }

    .detail-section {
        text-align: center;
        line-height: 2rem;
        padding: 0.3rem;
        border-bottom: 1px solid var(--lightGrayBorder);
    }

    .detail-section:nth-last-child(1) {
        border-bottom: none;
    }

    .selection-run-logs-details-header {
        font-size: 1.05rem;
        line-height: 2rem;
        text-align: center;
        color: var(--primaryBlack)222;
        width: 100%;
        background: var(--modalGrayBackground);
    }

    .selection-run-logs-details-step {
        border-bottom: 1px solid var(--lightGrayBorder);

        h2 {
            text-align: center;
            font-size: 1.05rem;
            line-height: 2rem;
        }
    }

    .selection-run-logs-details-step:nth-last-child(1) {
        border-bottom: none;
    }
}

#selection-run-logs-error-message {
    position: relative;

    .slds-modal__container {
        width: 30rem;
        padding: 0;

        .slds-modal__title {
            font-weight: 400;
        }
    }

    .slds-modal__content {
        min-height: 4rem;
    }

    .slds-modal__header {
        padding: 0;
    }

    #error-message-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.40);
        line-height: 1.25rem;
    }

    #close-error-message-modal-button {
        top: 0.25rem;
        right: 0.25rem;
        width: 1.5rem;
        height: 1.5rem;
    }
}
