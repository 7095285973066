.settings-dropdown{
    .disabled-dropdown-item {
        cursor: default !important;
        color: grey !important;
    }

    #prio-deduplication-disabled {
        display: flex;
        align-items: center;
    }

    .disabled-overlay-priodedup{
        background-color: var(--disabledOverlayOrange);
        .upgrade-badge-icon {
            bottom: auto;
            right: 12px;
            z-index: 9;
        }
    }

    .guidance-tip-container{
        position: absolute;
        right: 0.5em;
    }
    #settings-menu{
        border-radius: 0.25rem;
    }
}
