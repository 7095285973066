.timezone-dropdown-container-from {
  margin-bottom: 0.5rem;
  position: relative;

  .label-container-from {
    display: flex;
    justify-content: space-between;
  }

  .convert-from-timezone-notification {
    font-size: 0.75rem;
    height: 1.1rem;
    padding-top: 0.1rem;
    text-align: end;
    padding-right: 1.5rem;

    button {
      text-decoration: underline;
      border:none;
      color: #222;
      padding: 0;
      font-weight: 600;
    }

    &.true {
      position: absolute;
      top: -1.2rem;
      right: 0;
    }
  }

  .timezone-dropdown-tooltip-container {
    display: flex;
    align-items: center;
    width: 100%;

    div:first-child {
      width:100%;
    }

    .tooltip-container {
      width: 1rem;
    }

    .tooltip-in-timezone-container {
      left: -7rem;
      width: 9rem;
    }
  }
}