.waterfall_view {
    flex-direction: column;

    .warning-notification {
        margin: 0.1rem 0.5rem;

        .warning-run-different-selections {
            margin: 0.5rem 0;
        }
    }
}
