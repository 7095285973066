.insertField-container {
    position:relative;
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid var(--lightGrayBorder);
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--white);
    z-index: 2;

    .insertField-content {
    display: flex;
    width:100%;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 0.5rem;
    }

    .insertField-title{
        font-weight: 700;
        padding-bottom:0.5rem;
        color: var(--primaryDarkGray);
    }

    .target-data-extension-dropdown{
        width: calc(100% - 7em) !important;
        min-width: 7rem !important;

        div.text, input {
            width:100% !important;
            padding: 0px 1rem 0 0px;
        }
    }

    .dropdown-field {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0.5rem 0;
        flex-wrap: wrap;

        .dropdown-title {
            max-width: 7rem;
        }
    }

    #applyFormula-insert-field-dropdown {
        .menu {
          max-height: 7.2rem !important ;
        }
    }

    #applyFormula-insert-DE-dropdown {
        .menu {
          max-height: 10.2rem !important ;
        }
    }

    #applyFormula-insert-button {
        width:fit-content;

        &:disabled {
            #plus-icon {
                color: var(--lightGrayBorder);
            }
        }

        #plus-icon {
          margin-left: 10px;
          color: var(--primaryBlue);
          width: 1.3rem;
          height: 1.3rem;
         }
      }
}