.selection-container {
    border: 1px solid var(--grayBorder);
    border-radius: .25rem;
    box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    height: fit-content;
    margin-top: 2.6rem;
    overflow: auto;

    .selections {
        min-height: 4rem;
        width: 100%;
        max-width: 100%;
        min-width: 88%;
        box-shadow: 0 2px 2px 0 var(--primaryBoxShadowGray);
        border: 2px dashed transparent;
    }

    .selection-drop {
        font-weight: 500;
        letter-spacing: 0.1rem;
        line-height: 2rem;
        text-align: center;
        padding: 1rem;
        color: var(--grayBorder);
        text-transform: uppercase;
        border: .1rem dashed transparent;
        transition: .3s all !important;
        border: var(--transparentBorder);
    }

    .selection-dropzone {
        border: 1px solid transparent;
        padding: 0.5rem;

         &.isDragging {
            border: var(--solidBorder);
         }

        ~ div {
            display: none;
        }
    }
}
