.rightbar {
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: column-reverse;

    height: 100vh;
    width: var(--rightbarWidth);

    z-index: 700;
    background-color: var(--mostlyWhiteBackground);

    .slds-dropdown {
        margin-right: unquote("max(5px, 3rem)");
        margin-top: unquote("max(-115px, -12rem)");
    }

    &_admin,
    &_support {
        margin-left: -11px;
    }

    .rightbar_support {
        margin-bottom: 4rem;
    }

    .rightbar_support-button {
        margin-bottom: 10px;
    }

    button {
        margin: 0 auto;

        // Perfect circle border
        display: block;
        transform: scale(0.48);
        border-radius: 50%;
        width: 57px;
        height: 57px;

        // Default
        &,
        &:active,
        &:focus {
            border: none;
            color: var(--blackTitle);
            background: var(--lightGrayScrollTrack);
        }

        &:hover {
            border: 1px solid var(--lightGrayScrollTrack)40;
            color: var(--focusBlue);
            background: var(--mostlyWhiteBackground);
        }
    }

    .tips-toggle-button-active {

        &,
        &:active,
        &:focus {
            border: none;
            color: white;
            background-color: #FF9E2C;
        }

        &:hover {
            color: white;
            background-color: #FF9E2C;
        }
    }

    &_support>.slds-dropdown li {
        font-family: Salesforce Sans, Arial, sans-serif !important;
        font-size: 13.25px;
    }
    .deedee-ai-option-container{
        /* Styles for images and icons */
        .deedee-ai-btn {
            width: 35px;
            height: auto;
            cursor: pointer;
            margin: 0 auto 1rem auto;
            display: block;
          }
    }
}
