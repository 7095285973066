#subquery-modal-dialog {
    max-width: none;
    width: 95%;
    margin: 0 auto;

    .slds-modal__container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .subquery-dialog {
        display: table !important;
        width: 100%;

        // .slds-backdrop_open {
        //     top: 292px;
        //     height: calc(100% - 584px);
        // }
    }

    .slds-modal__footer {
        z-index: -1 !important;
    }

    .modal-content {
        .modal-header {
            border-bottom: 2px solid var(--lightGrayBorder);
            background-color: var(--white);
            .h4 {
                padding-left: 2.1rem;
            }
        }
        .modal-body {
            padding: 1rem 2.4rem;

        }

        .modal-footer {
            border-top: 2px solid var(--lightGrayBorder);
            background-color: var(--modalGrayBackground);
        }
    }
}