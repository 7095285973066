#selection-modal-name {
  overflow: auto;
  background: linear-gradient(62deg, #FF9900 0%, #f7cf68 100%);

  .slds-modal__container {
    width: 35rem;
  }

  #nameCopyError-1 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    height: 1.3rem;
    font-size: 0.9rem;

  }
}
